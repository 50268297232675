import { useEffect, useState } from "react";

import { i18n } from "../../../../../localization/i18n";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Modal } from "../../../../../components/Modal";
import { OutlinedButton } from "../../../../../components/OutlinedButton";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import { OutlinedCheckbox } from "../../../../../components/OutlinedCheckbox";

import { IOrder } from "../../dtos";

import { RiCloseLine } from "react-icons/ri";

import {
  EditOrderUrgencyModalContainer,
  Header,
  Content,
  InputContainer,
  CheckboxContainer,
  Footer,
} from "./styles";
import { showToast } from "../../../../../components/CustomToast";
import api from "../../../../../services/api";
import { roles } from "../../../../../utils/constants";
import { useAuth } from "../../../../../hooks/AuthContext";

interface EditOrderUrgencyModalProps {
  open: boolean;
  order: IOrder;
  handleCloseModal: () => void;
  onOrderUpdated: () => Promise<void>;
}

export function EditOrderUrgencyModal({
  open,
  order,
  handleCloseModal,
  onOrderUpdated,
}: EditOrderUrgencyModalProps) {

  const { user } = useAuth()
  const [taxes, setTaxes] = useState({
    urgency_tax: 0,
    custom_tax: 0,
  });
  const [loading, setLoading] = useState(false);
  const [urgencyInfos, setUrgencyInfos] = useState({
    urgency_type: "",
    justify: "",
    total: 0,
    urgency_tax_applied: false,
    custom_tax_applied: false,
  });

  async function handleUpdateOrderUrgency() {
    setLoading(true);

    try {
      const body = {
        total: urgencyInfos.total,
        urgency_type: urgencyInfos.urgency_type,
        urgency_reason: urgencyInfos.justify,
        reduced_taxes:
          urgencyInfos.custom_tax_applied && urgencyInfos.urgency_tax_applied
            ? "none"
            : urgencyInfos.custom_tax_applied &&
              !urgencyInfos.urgency_tax_applied
              ? "urgency"
              : !urgencyInfos.custom_tax_applied &&
                urgencyInfos.urgency_tax_applied
                ? "custom"
                : "both",
      };

      await api.put(`/orders/control/urgency/${order.id}`, body);
      await onOrderUpdated();

      handleCloseModal();
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao atualizar a urgência do pedido",
      });
    } finally {
      setLoading(false);
    }
  }

  function changeUrgencyInfos(data: Partial<typeof urgencyInfos>) {
    setUrgencyInfos((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  async function getUserCurrency() {
    const response = await api.get(`/currencies/${order.user_id}`);
    setTaxes({
      custom_tax: Number(response.data.custom_tax),
      urgency_tax: Number(response.data.urgency_tax),
    });
  }

  useEffect(() => {
    setUrgencyInfos({
      urgency_type: order?.urgency_type,
      justify: order?.urgency_reason ?? "",
      total: Number(order.total),
      custom_tax_applied:
        order.reduced_taxes !== "both" && order.reduced_taxes !== "custom",
      urgency_tax_applied:
        order.reduced_taxes !== "both" && order.reduced_taxes !== "urgency",
    });

    if (user.role === roles.licensee || user.role === roles.employee || user.role === roles.fixiter) {
      getUserCurrency();
    }
  }, [order, open]);

  return (
    <Modal open={open} modalWidth={480}>
      <EditOrderUrgencyModalContainer>
        <Header>
          <span className="title">Editar urgência</span>
          <RiCloseLine
            onClick={() => {
              handleCloseModal();
            }}
          />
        </Header>

        <Content>
          <RadioGroup
            value={urgencyInfos.urgency_type}
            onChange={(event) => {
              changeUrgencyInfos({ urgency_type: event.target.value });
            }}
          >
            <FormControlLabel
              value="normal"
              control={<Radio />}
              label="Normal"
            />
            <FormControlLabel
              value="requested"
              control={<Radio />}
              label="Solicitada"
            />
            <FormControlLabel
              value="approved"
              control={<Radio />}
              label="Aprovada"
            />
          </RadioGroup>

          <CheckboxContainer>
            <OutlinedCheckbox
              label="Taxa de urgência"
              checked={urgencyInfos.urgency_tax_applied}
              handleChange={() => {
                if (urgencyInfos.urgency_tax_applied) {
                  setUrgencyInfos((prevState) => ({
                    ...prevState,
                    total: urgencyInfos.total - taxes.urgency_tax,
                    urgency_tax_applied: false,
                  }));
                  return;
                }
                setUrgencyInfos((prevState) => ({
                  ...prevState,
                  total: urgencyInfos.total + taxes.urgency_tax,
                  urgency_tax_applied: true,
                }));
              }}
            />
            <OutlinedCheckbox
              label="Taxa de personalização"
              checked={urgencyInfos.custom_tax_applied}
              handleChange={() => {
                if (urgencyInfos.custom_tax_applied) {
                  setUrgencyInfos((prevState) => ({
                    ...prevState,
                    total: urgencyInfos.total - taxes.custom_tax,
                    custom_tax_applied: false,
                  }));
                  return;
                }
                setUrgencyInfos((prevState) => ({
                  ...prevState,
                  total: urgencyInfos.total + taxes.custom_tax,
                  custom_tax_applied: true,
                }));
              }}
            />
          </CheckboxContainer>

          <InputContainer>
            <OutlinedInput
              inputName="urgency_justify"
              label="Justificativa da urgência"
              textArea
              value={urgencyInfos.justify}
              handleChange={(event) => {
                changeUrgencyInfos({ justify: event });
              }}
            />

            <div className="total">
              <strong>{`${i18n.t("orders.confirmation.totalValue")}`}: </strong>
              <span>
                {urgencyInfos.total.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </div>
          </InputContainer>
        </Content>

        <Footer>
          <OutlinedButton
            buttonColor="var(--finished)"
            text="Salvar alterações"
            onClick={handleUpdateOrderUrgency}
            loading={loading}
          />
        </Footer>
      </EditOrderUrgencyModalContainer>
    </Modal>
  );
}
