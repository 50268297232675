import {
  ITranslateNoInfo,
  ILanguageMessages,
  ITranslateCreators,
  ITranslateCustomSolution,
  ITranslateDashboard,
  ITranslateDisabled_user,
  ITranslateDropZone,
  ITranslateErrorMessages,
  ITranslateEvaluation,
  ITranslateFileList,
  ITranslateFinancial,
  ITranslateForm_Errors,
  ITranslateGlobal,
  ITranslateGoBackBtn,
  ITranslateNavMenu,
  ITranslateOptionsBtn,
  ITranslateOrderBox,
  ITranslateOrders,
  ITranslateProfile,
  ITranslateSearchBar,
  ITranslateSidebar,
  ITranslateSolutions,
  ITranslateStandardSolution,
  ITranslateSuccess,
  ITranslateSurgicalPlanning,
  ITranslateTopBar,
  ITranslateUserRegistration,
  ITranslateFixiter,
  ITranslatePrinter,
  ITranslateCourses,
  ITranslateLogin,
  ITranslateTips,
  ITranslateAddress,
  ITranslatePerson,
  ITranslateCoins,
  ITranslateUserProfile,
  ITranslateLanguage,
  ITranslateBills,
  ITranslateTransactions,
} from "./dtos";

const language: ITranslateLanguage = {
  userLanguage: "pt",
};

const transactions: ITranslateTransactions = {
  header: "Transações",
};

const noInfo: ITranslateNoInfo = {
  partner: "Você ainda não tem nenhum parceiro cadastrado.",
  patient: "Você ainda não tem nenhum paciente cadastrado.",
  address: "Você ainda não tem nenhum endereço cadastrado.",
  card: "Você ainda não tem nenhum cartão cadastrado.",
  transaction: "Você ainda não tem nenhuma transação cadastrada."
};

const bills: ITranslateBills = {
  bills: "Faturas",
  completePayment: "Pagar restante da fatura",
  value: "Valor",
  valueToBePaid: "Valor a ser pago:",
  card: "Cartão",
  ticket: "Boleto",
  pix: "PIX",
  paymentCard: "Cartão para pagamento",
  didntFindYourCard: "Não encontrou seu cartão?",
  didntFindYourAddress: "Não encontrou seu endereço?",
  billing_address: "Endereço de cobrança",
  register: "Cadastre um",
  pay: "Realizar pagamento",
  linkToAccessTicket: "Link para acesso ao boleto em PDF",
  barCode: "Código de barras",
  pixCopyAndPaste: "PIX Copia e Cola",
  changePaymentMethod: "Alterar método de pagamento",
  requestBill: "Solicitar cobrança",
  latePaymentInfo:
    "*Pagamentos feitos após o vencimento, multa de 10% e juros de 2%",
  dataSentByMail: "Dados enviados via email",
  error: {
    payment: "Não foi possivel realizar o pagamento",
    transaction: "Ocorreu um erro na transação",
    mailBill: "Não foi possivel realizar o pagamento",
    insufficientFunds: "Saldo insuficente",
  },
  success: {
    payment: "Pagamento realizado com sucesso",
    mailBill: "Cobrança solicitada com sucesso",
  },
};

const userProfile: ITranslateUserProfile = {
  addresses: "Meus endereços",
  patients: "Meus pacientes",
  partners: "Meus parceiros",
  paymentInfos: "Cartões",
  users: "Meus usuários",
  closure: "Financeiro",
  myProfile: "Meu perfil",
  addressLabel: "Endereços",
  patientLabel: "Pacientes",
  userLabel: "Usuários",
  partnerLabel: "Parceiros",
  examsLabel: "Exames",
  closureResume: "Resumo do faturamento",
  ordersMade: "Pedidos feitos",
  ordersPaid: "Pedidos pagos",
  descriptions: {
    fixPoint: "Quantidade de pedidos que você é provedor.",
    closure: "Pedidos feitos entre:",
    ordersPaid: "Total em pedidos que foram pagos por você.",
    ordersMade: "Total de pedidos feitos durante o período de fechamento.",
    ordersCourtesy: "Total em pedidos que foram marcados como cortesia.",
    fixPointPopover: "Verificar quais pedidos",
    closurePopover: "Verificar os pedidos realizados no período vigente",
  },
  my_profile: "Meu Perfil",
  profile_picture: {
    register: "Cadastrar Foto de Perfil",
    register_profile_picture: "Cadastrar",
    adjust: "Clique para cadastrar sua foto de perfil e ajustá-la.",
    success: "Foto de perfil cadastrada com sucesso!",
    error:
      "Um erro ocorreu no cadastro da sua foto de perfil. Por favor, tente novamente mais tarde.",
  },
  password: {
    success: "¡Contraseña alterada con éxito!",
    error: "Hubo un problema al cambiar la contraseña.",
  },
  edit_profile_data: "Editar Dados Pessoais",
  edit_user_data: "Editar Dados do Usuário",
  edit_licensee_data: "Editar Dados da Licença",
  no_permission: "Você não tem permissão para alterar os dados do licenciado.",
  yes: "Sim",
  no: "Não",
  profile_data: "Dados Pessoais",
  user_data: "Dados do Usuário",
  licensee_data: "Dados da Licença",
  personal_data: {
    document: "Documento",
    phone: "Telefone",
    country: "País",
    state: "Estado",
    city: "Cidade",
    success: "Dados pessoais atualizados com sucesso!",
    naturalPerson: "Pessoa Física",
  },
  data_user: {
    email: "E-mail",
    name: "Nome",
    update: "Atualizar Dados de Usuário",
  },
  data_licensee: {
    fantasy_name: "Nome Fantasia",
    enterprise_name: "Nome Empresarial",
    document: "CPF | CNPJ",
    situation: "Situação",
    phone: "Telefone",
    company_phone: "Telefone da Empresa",
    contract: "Contrato",
    printer: "Impressora",
    fixpoint: "FixPoint",
    update: "Atualizar Dados de Licenciado",
    manager_name: "Gerente da Licença",
    instagram: "Instagram",
    region: "Região",
    printers: "Impressoras",
  },
  selectedPrinters: "Impressoras Selecionadas",
  noPrinterSelected: "Nenhuma Impressora Selecionada",
  actualClosure: "Faturado em aberto",
  previousClosure: "Faturado fechado",
};

const fixiterOnboarding: ITranslateFixiter = {
  introduction:
    "Tudo pronto para começar a oferecer o melhor para seus pacientes?",
  proceed: "Continuar",
  initialKit: "Solicitar kit inicial",
  firstStepHeader:
    "Conheça mais um pouco sobre a tecnologia da Fix it nesse video:",
  secondStepHeader: "Tudo sobre a nossa tecnologia de impressão 3D",
  thirdStepHeader: "Agora vamos aprender a aplicar a PunhoFix",
  firstDescription:
    "Mas antes de começar, vamos te explicar como vai funcionar, ok?",
  description: "Nosso processo de inicialização consiste em duas etapas:",
  stepOne: "Tecnologia Fix it",
  stepTwo: "Aplicação da principal órtese (PunhoFix)",
  stepThree: "Como utilizar a Plataforma",
  startTheOnboard: "COMEÇAR",
  startToUseTheApp: "COMEÇAR A USAR O APP",
  sendCertificate: "Enviar foto do meu diploma",
  send: "Enviar",
  cancel: "Cancelar",
  sendDiploma: "Enviar diploma",
  document_success: "Documento enviado com sucesso!",
  document_failed:
    "Não foi possível enviar seu documento! Por favor, entre em contato com o SAF.",
  chooseFile: "Selecione um arquivo para enviar (.png, .jpeg, .jpg)",
  selectSolutions: "SELECIONAR SOLUÇÕES",
  conclusionHeader: "Falta pouco para você se tornar um Fixiter!",
  firstConclusionDescription:
    "Mas parabéns por ter finalizado os treinamentos da plataforma, já podemos dizer que você faz parte da Fix it e da revolução na saúde. Agora só precisamos validar suas informações para liberar seu acesso.",
  conclusionDescription:
    "Envie uma foto do seu diploma para o nosso WhatsApp, daremos um retorno em até 24horas validando seu acesso. Enquanto isso, não perca tempo, clique no botão abaixo para ser redirecionado e valide seu usuário. Assim que aprovado, você já vai conseguir realizar o seu primeiro pedido e entregar a melhor experiência para o seu paciente!",
  attachments: {
    header: "Anexos",
    portfolio: "Baixar o portfólio",
    tools: "Link para compra das ferramentas e insumos",
    startKit: "Realizar pedido do Kit Inicial",
    goodPractices:
      "Guia de boas práticas com a tala Fix it, para o profissional entregar ao paciente",
    heatBlower: "Soprador térmico",
    scissors: "Tesoura",
    measuringTape: "Fita métrica",
    velcro: "Velcro",
    tubularMesh: "Malha tubular (10cm de diâmetro)",
  },
  stepTwoDescription: `• Nessa próxima etapa você precisará seguir algumas orientações:
  A aplicação é muito simples, mas deverá ter em mãos as ferramentas necessárias,
  caso não tenha, é só acessar o link aqui embaixo:`,
  firstConclusionWelcome: "Nossas boas-vidas ao futuro da saúde!",
  initialKitDescription:
    "• Agora com todas as orientações seguidas, vamos continuar com o vídeo da aplicação da nossa órtese PunhoFix?",
  attachmentsToolDescription: `• Os links abaixo são referências para compra, grande parte
  dos insumos e ferramentas você encontra em lojas próximas à
  você, diminuindo tempo e custo de frete.`,
  attachmentsStartKitDescription:
    "• Para praticar basta solicitar aqui embaixo o seu Kit inicial, lembrando que ele é totalmente gratuito.",
};

const login: ITranslateLogin = {
  email: "E-mail",
  password: "Senha",
  forgotPassword: "Esqueci minha senha",
  login: "Faça seu login",
  register: "Registre-se",
  submit: "Entrar",
  dontHaveAccount: "Não tem uma conta?",
  recover_password: "Recuperação de Senha",
  send_code: "Enviar código de redefinção",
  new_password: "Nova Senha",
  new_password_confirmation: "Confirme a Nova Senha",
  change_password: "Alterar Senha",
  password_changed: "Senha modificada com sucesso!",
  starting: "Inicializando",
};

const tips: ITranslateTips = {
  dontShowAgain: "Não mostrar novamente",
  title: "Novas funcionalidades",
  create: "Nova tip",
  edit: "Editar",
  delete: "Excluir",
  createButton: "Criar tip",
  editButton: "Salvar",
  name: "Nome",
  users: "Usuários",
  type: "Tipo",
  description: "Descrição",
  success: "Tip criada com sucesso",
  descriptionError: "Ocorreu um erro ao tentar carregar a novidade",
  error: "Ocorreu um erro ao tentar criar a tip",
  page: "Página",
  updateButton: "Atualizar",
  updateError: "Ocorreu um erro ao tentar editar a tip",
  changeStateError: "Ocorreu um erro ao tentar atualizar a tip",
  specificUrl: "Parâmetro",
  activateTip: "Ativar tip",
  cancel: "Cancelar",
  confirm: "Confirmar",
  deleteTip: "Deletar tip",
  deleteError: "Ocorreu um erro ao tentar deletar a tip.",
  globalWarning:
    "Identificamos que já existe uma tip global ativada, deseja desativá-la para ativar essa?",
  pageWarning:
    "Identificamos que já existe uma tip para essa página ativada, deseja desativá-la para ativar essa?",
  deleteWarning: "Essa tip ainda está ativa, tem certeza que deseja excluir?",
  new: "Novidades",
};

const userRegistration: ITranslateUserRegistration = {
  connector: "e",
  stepOne: "Criar conta",
  stepTwo: "Dados cadastrais",
  stepThree: "Dados empresariais",
  stepFour: "Planos",
  nextStep: "Avançar",
  prevStep: "Voltar",
  name: "Nome",
  email: "E-mail",
  password: "Senha",
  password_confirmation: "Confirmação da senha",
  fillCodes: "Digite o código que enviamos para seu email",
  fillNameAndEmail: "Preencha seu e-mail e senha",
  fillData: "Preencha seus dados",
  country: "País",
  cpf: "Cadastro de pessoa física (CPF)",
  cellphone: "Celular",
  state: "Estado",
  city: "Cidade",
  terms: "Termos de uso",
  policy: "Políticas de privacidade",
  termsOfUse: `Li e aceito as`,
  companyNumber: "CNPJ",
  companyData: "Preencha os dados de sua empresa",
  companyName: "Razão social",
  tradingName: "Nome fantasia",
  continueAsNaturalPerson: "Continuar como pessoa física",
  plans: "Escolha seu plano",
  choosePlan: "Escolher plano",
  choosedPlan: "Selecionado",
  errorChoosePlan: "Escolha um plano",
  errorAsigneePlan: "Ocorreu um erro com o plano",
  errorCreatePerson: "Ocorreu um erro ao criar o usuário",
  termsError: "Ocorreu um erro ao carregar o documento",
  validCode: "Digite um código válido.",
  emailInUse: "Email em uso.",
  codeExpires: "O código expira em: ",
  sendCode: "Reenviar código",
  sendedCode: "Código reenviado!",
  codeExpired: "Código expirado.",
  professional_number: "Número do conselho de classe",
  expertise: "Especialidade",
  instagram: "Instagram",
  notOperational: "Ops! A Fix it ainda não opera em sua região.",
  noOperationalDescription:
    "Entre em contato com nosso atendimento e saiba como levar a Fix it até você",
  contactUs: "Contate-nos",
  registerCompanyAddress: "Registrar endereço de atendimento",
  finishLater: "Escolher depois",
  fillManagerData: "Preencha os dados do responsável",
  confirm: "Confirmar",
};

const creators: ITranslateCreators = {
  solutions: "Gerenciar soluções",
  make: "Produzir",
  addFile: "Adicionar arquivo",
  side: "Lado",
  size: "Tamanho",
  part: "Parte",
};

const global: ITranslateGlobal = {
  underConstruction: "Disponível em breve",
  solutions: "Soluções",
  situation: "Situação",
  undo: "Desfazer",
  regions: {
    southeast: "Sudeste",
    south: "Sul",
    north: "Norte",
    northEast: "Nordeste",
    midwest: "Centro-Oeste",
    international: "Internacional",
  },
  customerSituation: {
    effected: "Venda efetivada",
    implementation: "Em implantação",
    operational: "Em operação",
    training: "Em treinamento",
    onboarding: "Onboarding",
  },
  licensees: {
    header: "Licenciados",
    create: "Criar Licenciado",
    edit: "Editar licenciado",
    field: {
      document_number: "CNPJ/CPF",
      email: "E-mail",
      phone: "Telefone",
      instagram: "Instagram",
      is_public: "Público",
      natural_person: "Pessoa",
      legal_entity: "Empresa",
      cpfMask: "999.999.999-99",
      cnpjMask: "99.999.999/9999-99",
      manager_name: "Gerente",
      is_test: "Licenciado teste",
    },
  },
  designers: {
    header: "Designers",
    create: "Criar designer",
    edit: "Editar designer",
  },
  configs: {
    header: "Configurações gerais",
    createUserTerms: "Novos termos de uso",
    solutionFields: "Áreas das soluções",
    createSolutionField: "Nova área",
    createSolutionCategory: "Nova categoria",
    solutionCategories: "Categorias de",
    editSolutionField: "Editar ",
    editSolutionCategory: "Editar ",
    docs: {
      header: "Documentos - Contrato {{contract}}",
      label:
        "Estes documentos podem ser visualizados ao longo do aplicativo para utilização geralmente em contratos de aceite como termos de uso e política de privacidade.",
    },
    printers: {
      header: "Impressoras homologadas",
      edit: "Editar impressora",
      create: "Criar impressora",
      firstStep:
        "Nesse primeiro passo, você precisa ter instalado o Driver da impressora (Mac ou Windows), então, com a impressora conectada em uma porta USB, clique no botão abaixo.",
      secondStep:
        "No segundo passo, vamos realizar algumas movimentações na impressora, totalmente offline, ou seja, este processo funciona mesmo desconectado da internet. O que testará a comunicação com o dispositivo.",
      conclusion:
        "Agora, está tudo pronto para iniciar uas impressões. Fique livre para aquecer a mesa e extrusor por aqui ou diretamente da impressora.",
      heatBed: "Aquecer mesa 65ºC",
      heatExtruder: "Aquecer bico 210ºC",
      configure: "Configurar impressora",
      connect: "Conectar impressora",
      notfound: "Não encontrou seu dispositivo?",
      manualSearch: "Busque aqui",
      test: "Iniciar teste",
      baudRateChangeSucess: "Baud Rate alterado com sucesso",
      changeBaudRateTo: "Alterar Baud Rate para",
    },
    users: {
      header: "Usuários internos",
      edit: "Editar usuário",
      create: "Criar usuário",
    },
    tips: {
      header: "Novas funcionalidades",
    },
  },
  novalue: "Limpar",
  status: {
    label: "Registro ativo",
    active: "Ativos",
    deactive: "Inativos",
    registered: "Registrados",
  },
  sides: {
    ESQ: "Esquerdo",
    DIR: "Direito",
    UNI: "Único",
  },
  roles: {
    LICENSEE: "Licenciado",
    FIXITER: "Fixiter",
    CREATOR: "Designers de Produtos",
    ADMIN: "Administrador",
    SAF: "Atendimento (SAF)",
    EMPLOYEE: "Usuário",
  },
  password: {
    password: "Senha Atual",
    password_confirmation: "Confirme a Nova Senha",
    new_password: "Nova Senha",
    save_changes: "Salvar Alterações",
    change_password: "Alteração de Senha",
  },
};

const dashboard: ITranslateDashboard = {
  header: "Relatórios",
  requestsDay: "Pedidos hoje",
  requestsMonth: "Pedidos no mês",
  requestsSevenDays: "Pedidos nos últimos 7 dias",
  requestsYear: "Pedidos em",
  revenueYear: "Faturamento em",
  clickSevenDays: "Cliques site nos últimos 7 dias",
  soon: "EM BREVE",
  nps: "NPS",
  months: {
    jan: "Jan",
    feb: "Fev",
    mar: "Mar",
    apr: "Abr",
    may: "Mai",
    jun: "Jun",
    jul: "Jul",
    aug: "Ago",
    sep: "Set",
    oct: "Out",
    nov: "Nov",
    dec: "Dez",
  },
  newPatients: "Novos pacientes",
  bill: "Fatura",
  filamentUse: "Consumo de filamento",
  filamentInfo: "Média calculada com base nos pedidos padronizados",
  error: "Não foi possivel carregar informações da dashboard",
  caseIncidence: "Incidencia de casos",
  mostAskedSolutions: "Soluções mais pedidas",
  patientsInfo: "Pacientes cadastrados durante período selecionado",
  month: "Mês",
  apply: "Aplicar",
  period: "Período",
};

const solutions: ITranslateSolutions = {
  header: "Soluções Fix it",
  select: "Selecione",
  whichSize: "QUAL TAMANHO?",
  aplicationVideo: "Vídeo de aplicação",
  categories: {
    header: "Que categoria de solução deseja?",
  },
  solutions: {
    header: "Escolha uma solução",
  },
  download: "Baixar",
  unique_part: "Unica",
  image_upload_title: "Atualizar imagem",
  image_upload_subtitle: "Imagem de exibição da solução",
  save_new_image: "Salvar nova imagem",
  edit_image: "Troque a imagem da solução",
  download_file_enable: "Habilitar",
  download_file_upload_title: "Atualizar download",
  download_file_upload_subtitle: "Arquivo para download da solução",
  custom_update_title: "Atualizar customizada",
  custom_update_subtitle: "Dados da solução customizada",
  standard_update_title: "Atualizar padronizada",
  standard_update_subtitle: "Dados da solução padronizada",
  size_patterns: "Padrões de tamanho",
  is_custom: "Solução para customização",
  is_standard: "Solução para padronização",
  is_download: "Solução para download",
  edit: "Editar solução",
  name: "Nome da solução",
  category: "Categoria",
  field: "Área",
  form_type: "Tipo de formulário",
  suggested_price: "Preço sugerido",
  side: "Lado",
  number_sides: "Lados",
  updateButton: "Salvar dados",
  number_parts: "Partes",
  part: "Parte",
  number_measures: "Medidas",
  link_guide: "Endereço do guia de medidas",
};

const success: ITranslateSuccess = {
  header: "Sucesso do cliente",
};

const orders: ITranslateOrders = {
  financial_status: {
    order_status: "Status do pedido",
    paid: "Pago",
    not_paid: "Faturado",
    courtesy: "Descontado",
    success: "Status do pedido atualizado com sucesso.",
    error: "Ocorreu um erro ao tentar atualizar o status do pedido.",
  },
  confirmation: {
    service_number: "Número de atendimento",
    confirm: "Confirme os dados do pedido",
    patient: "Paciente",
    years: "anos",
    solution: "Solução",
    custom: "Personalizada",
    standard: "Padronizada",
    profissional: "Profissional",
    partner: "Parceiro",
    measures: "Medidas",
    bodyPart: "Parte do corpo",
    injuryType: "Tipo de lesão",
    surgeryDate: "Data da cirurgia",
    target: "O que deve ser segmentado",
    markAsUrgency: "Marcar como urgência",
    urgencyDetails:
      "Pedidos com urgência não justificada terão o valor cobrado em dobro.",
    totalValue: "Valor total",
    justification: "Justificativa",
    responsabilityConfirmation:
      "Declaro que os dados informados são de minha responsabilidade e que estão todos corretos.",
    cancel: "Cancelar",
    confirmAndFinish: "Confirmar e finalizar",
  },
  urgency: {
    requested: "Solicitação de urgência pedido",
    requestedInfo: "Uma solicitação de urgência foi feita para o pedido",
    requestedApprove: "deseja aprová-la?",
    approve: "Aprovar",
    refuse: "Recusar",
    popoverRequested: "Urgência solicitada",
    popoverApproved: "Urgência aprovada",
    popoverNonJustified: "Urgência não justificada",
    additionalValueJustification:
      "Pedidos de personalizadas terão um acréscimo de {{value}} {{currency}}, em caso urgência, {{doubleValue}} {{currency}}",
  },
  actions: {
    new: "NOVO",
    edit: "EDITAR",
    save: "SALVAR",
    close: "FECHAR",
    discart: "DESCARTAR",
    upload: "UPLOAD",
  },
  pending_info: {
    text: "Informações pendentes",
  },
  patient_section: {
    search: {
      error: "Ocorreu um erro ao carregar os pacientes.",
    },
    empty_title: "Dados do paciente",
    empty_subtitle: "Idade, peso, telefone...",
  },
  partner_section: {
    search: {
      error: "Ocorreu um erro ao carregar os profissionais.",
    },
    empty_title: "Dados do parceiro",
    empty_subtitle: "Telefone, email...",
    entities: {
      natural_person: "PESSOA FÍSICA",
      legal_entity: "PESSOA JURÍDICA",
    },
  },
  solution_section: {
    empty_title: "FINALIZAR E ENVIAR",
    empty_subtitle: "Descreva o produto: cor, medidas...",
    measurement_guide: "Consultar guia de medidas",
  },
  exam_section: {
    empty_title: "DADOS DA AVALIAÇÃO",
    empty_subtitle: "Profissional, diagnóstico, membro...",
    professional_label: "Dados do profissional responsável",
  },
  planit_section: {
    title_template: "PLANEJAMENTO ",
    empty_title: "PLANEJAMENTO",
    empty_subtitle: "Profissional, lesão, arquivos...",
    doctor_subsection_title: "informações do médico/cirurgião responsável",
    evaluation_subsection_title: "Avaliação",
    files_subsection_title: "Arquivos",
  },
  order_type: {
    is_patient: "PARA MEU PACIENTE",
    is_partner: "PARA MEU PARCEIRO",
    is_fixiter: "PARA UM FIXITER",
  },
  create: {
    custom: {
      header: "Pedido personalizado",
      header_template: "Person. ",
    },
    standard: {
      header: "Pedido padronizado",
      header_template: "Padron. ",
    },
    plan: {
      header: "Plan. cirúrgico",
      header_template: "Plan. cirúrgico",
    },
  },
  printChoose: {
    header: "Controle de impressões",
    available_info: "Disponível {{available}} de {{quantity}}",
    standard: "Padrão",
    sides: "Lado",
    save: "Salvar e preparar impressão",
    prepare: "Preparar impressão",
    error: "Upsy! Impressão indisponível",
    advice: "Aguardando dados",
    waitingchoice: "Aguardando escolha",
    noprinter: "Por favor, selecione uma impressora",
    useStandardFileOption: "Manter {{print_option}}",
    proceed: "Continuar impressão - {{file_choice}}",
    demand: "Demanda de impressão - {{file_choice}}",
    end: "Encerrar impressão - {{file_choice}}",
    endAndSelect: "Encerrar e fazer nova escolha",
    add: "Adicionar nova escolha",
    progress: "Acompanhe o progresso",
    waiting: "FixPoint imprimindo - {{file_choice}}",
    notStarted: "Aguardando FixPoint",
    completed: "Impressão finalizada {{file_choice}}",
    printControl: {
      header: "Escolher peça",
      selectStream: "Imprimir {{print_option}}",
      completed: "Impressão finalizada {{print_option}}",
      tryagain: "Tentar novamente {{print_option}}",
      advice: "Aguardando escolha",
      error: "Não foi possível iniciar impressão",
      empty: "Nenhuma parte disponível para impressão",
      print: "Configurar impressão",
      printing: "Impressão em andamento {{print_option}}",
      preHeatingBedAndExtruder: "Aquecendo mesa e extrusor",
    },
  },
  creator: "Designer",
  licensee: "Cliente",
  print: "Imprimir",
  download: "Opções de download",
  view: "Detalhes",
  printHistory: "Histórico de impressão",
  printManagement: "Gerenciamento de impressão",
  printStarting: "Estamos prontos para imprimir,",
  downloadStarting: "Seu download está começando,",
  printingSuccess: "confira os dados!",
  clickToDownload: "Não começou? Clique aqui para baixar.",
  printingData: "Dados de impressão",
  printingTime: "Tempo",
  materialCost: "Custo",
  materialWeight: "Peso",
  custom_production_update_title: "Upload de customização",
  custom_production_update_subtitle: "Informações da produção",
  start_development: "Iniciar desenvolvimento",
  call_review: "Solicitar revisão",
  is_cancelled: "Pedido cancelado",
  commit_review: "Concluir revisão",
  header: "Pedidos",
  as_provider: "Demandas FixPoint",
  date: "Data",
  patient: "Paciente",
  partner: "Parceiro",
  solution: "Solução",
  type: "Tipo",
  category: "Categoria",
  quantity: "Quantidade",
  printer: "Impressora",
  state: "Situação",
  price: "Total R$",
  total: "Total ({{order_type}})",
  files: "Arquivos",
  selectPrinter: "Selecionar uma impressora...",
  sendToPrinter: "Enviar para a impressora",
  conclude: "Concluir",
  didNotFindPrinter: "Não encontrou sua impressora na lista?",
  printComplete: "A impressão foi concluída?",
  didNotFoundNavigator: "Parece que o seu navegador não é o Chrome!",
  chromeDownload1:
    "Para melhor utilização dos sistemas FixIt, recomendamos que você o utilize!",
  chromeDownload2: "Ou você pode baixá-lo aqui:",
  clickToProceed: "Clique para continuar",
  patientNotSet: "Não informado",
  status: {
    processing: {
      value: "processing",
      optionText: "Não iniciado",
    },
    development: {
      value: "development",
      optionText: "Em desenvolvimento",
    },
    review: {
      value: "review",
      optionText: "Em análise",
    },
    cancelled: {
      value: "cancelled",
      optionText: "Cancelado",
    },
    completed: {
      value: "completed",
      optionText: "Concluído",
    },
    expired: {
      value: "expired",
      optionText: "Expirado",
    },
  },
  fields: {
    status: "Status",
  },
  cancelConfirmation: "Tem certeza que deseja cancelar o pedido?",
  qtd: "QTD",
  order_provider: {
    requester: "Solicitante",
    order_data: "Dados do pedido",
    company_name: "Nome da empresa:",
    fantasy_name: "Nome fantasia:",
    responsible: "Responsável:",
    location: "Localidade:",
  },
  filter: {
    sublicensee: "Sublicenciado",
  },
  planit: {
    submit_file_upload: "Enviar imagem",
    file_upload: "Upload de imagem",
  },
};

const profile: ITranslateProfile = {
  header: "Minha unidade",
  unit: "Unidade",
  email: "Email",
  state: "Situação",
  region: "Região",
  managerName: "Nome do gestor",
  phone: "Telefone",
  instagram: "Instagram",
  editProfile: "Editar perfil",
  changePassword: "Alterar senha",
  printers: "Impressoras",
  addPrinter: "Adicionar impressora",
};

const sidebar: ITranslateSidebar = {
  reports: "Relatórios",
  solutions: "Soluções",
  requests: "Pedidos",
  files: "Arquivos",
  success: "Sucesso",
  designers: "Designers",
  licensees: "Licenciados",
  config: "Config.",
  signout: "Sair",
  prices: "Preços",
  plans: "Planos",
  onboarding: "Onboarding",
  courses: "Cursos",
  financial: "Financeiro"
};

const navMenu: ITranslateNavMenu = {
  reports: "Relatórios",
  solutions: "Soluções",
  requests: "Pedidos",
  files: "Arquivos",
  success: "Sucesso",
};

const topbar: ITranslateTopBar = {
  coinFix: {
    start: "Você tem",
    end: "FixCoin",
  },
  notification: "Uhul, você tem {{ quantity }} notificações!",
  editProfile: "Editar perfil",
  signout: "Sair",
  noprinter: "Sem impressora",
  fixpoint: "FixPoint",
  change_password: "Alteração de Senha",
  my_users: "Meus Usuários",
  filamentData: "Dados do Filamento",
  addPerson: "Cadastrar dados pessoais",
};

const dropZone: ITranslateDropZone = {
  dragActive: "Clique ou arraste para adicionar",
  error: "Arquivo não suportado",
  success: "Solte os arquivos aqui",
};

const fileList: ITranslateFileList = {
  delete: "Excluir",
};

const goBackBtn: ITranslateGoBackBtn = {
  back: "Voltar",
};

const optionsBtn: ITranslateOptionsBtn = {
  successMessage: "Pedido cancelado com sucesso.",
  evaluation: "Acessar avaliação",
  problem: "Tive um problema",
  cancel: "Cancelar pedido",
  printHistory: "Histórico de impressão",
};

const orderBox: ITranslateOrderBox = {
  date: "Data",
  patient: "Paciente",
  solution: "Solução",
  category: "Categoria",
  quantity: "Quantidade",
  printer: "Impressora",
  state: "Situação",
  price: "Total R$",
  files: "Arquivos",
};

const searchBar: ITranslateSearchBar = {
  placeholder: "Pesquise por algo...",
};

const surgicalPlanning: ITranslateSurgicalPlanning = {
  personalize: "Planejamento cirúrgico",
  nullTargetField: "Informe o que deve ser segmentado",
  segmentation: "Segmentação",
  doctorInformation: "Informação do médico/Cirurgião responsável",
  partOfTheBody: "Parte do corpo",
  lesionType: "Tipo de lesão",
  whatShouldBeSegmented: "O que deve ser segmentado",
  surgeryDate: "Data da cirurgia ",
};

const standardSolution: ITranslateStandardSolution = {
  buy: "Padronizada",
  request: "Encomendar",
  size: "Tamanhos",
  side: "Lado",
  quantity: "Quantidade",
  printingInfo: "Informações de impressão",
  selectPrinter: "Impressora",
};

const customSolution: ITranslateCustomSolution = {
  buy: "Personalizada",
  updated_at: "Atualizado à",
  updated_at_suffix: "min.",
  noFilePrice: "Aguardando dados do formulário para cálculo de preço",
  noSelectedPrinter: "Nenhuma impressora selecionada",
  noStandardFile: "Não existe arquivo disponível para a configuração informada",
  totalPrice: "Total:",
  previous: "Anterior",
  next: "Próximo",
  successToast: "Pedido realizado com sucesso!",
  errorToast: "Erro ao realizar o pedido! Detalhes :",
  sendOrder: "Enviar pedido",
  personalize: "Personalizar",
  selectPrinter: "Escolha a impressora",
  step: "Etapa",
  of: "de",
  patientData: "Informações do paciente",
  fullName: "Nome completo",
  company_name: "Razão social",
  professional_number: "Registro profissional",
  cpf: "CPF",
  cnpj: "CNPJ",
  cep: "CEP",
  contact: "Contato",
  contactMask: "(99) 99999-9999",
  cepMask: "99999-999",
  measuresError: "Por favor, adicione as medidas.",
  nameError: "Escreva o nome completo do paciente!",
  professionalNameError: "Digite o nome do profissional.",
  diagnosisError: "Descreva o diagnóstico realizado.",
  age: "Idade",
  months: "Meses",
  weight: "Peso (kg)",
  height: "Altura (cm)",
  responsibleName: "Nome do responsável",
  responsiblePlaceholder: "Se menor de idade ou portador de deficiência",
  phone: "Celular",
  phonePlaceholder: "(00) 00000-0000",
  email: "E-mail",
  emailPlaceholder: "exemplo@usefixit.com.br",
  evaluation: "Avaliação",
  hasIndication: "Tem indicação",
  previousUse: "Uso prévio de órtese",
  professionalName: "Nome do profissional que avaliou",
  occupation: "Área de atuação",
  diagnosis: "Diagnóstico",
  limb: "Membro",
  previousOrthesis: "Órtese utilizada anteriormente",
  previousOrthesisPlaceholder: "Ex.: Gesso, placa termoplástica, etc...",
  finishingOrder: "Finalizando pedido",
  measures: "Medidas",
  measuresGuide: "Consultar guia de medida",
  filamentColor: "Cor do filamento",
  observations: "Observações",
  observationsPlaceholder:
    "Ex.: Membro edemaciado, medidas do membro contralateral, etc...",
  colors: {
    aluminum: "Alumínio",
    yellow: "Amarelo",
    skyBlue: "Azul Céu",
    cobaltBlue: "Azul Cobalto",
    darkBlue: "Azul Escuro",
    metalicBlue: "Azul Metálico",
    oceanBlue: "Azul Oceano",
    white: "Branco",
    bronze: "Bronze",
    gray: "Cinza",
    golden: "Dourado",
    silver: "Prata",
    black: "Preto",
    orange: "Laranja",
    oilOrange: "Laranja Perolado",
    lilac: "Lilás",
    ivory: "Marfim",
    natural: "Natural",
    pistachio: "Pistache",
    pink: "Rosa",
    purple: "Roxo",
    darkPurple: "Roxo Escuro",
    metalicPurple: "Roxo Metalizado",
    green: "Verde",
    darkGreen: "Verde Escuro",
    forestGreen: "Verde Floresta",
    tiffanyGreen: "Verde Tiffany",
    red: "Vermelho",
  },
  jobs: {
    physiotherapist: {
      value: "physiotherapist",
      optionText: "Fisioterapeuta",
    },
    orthopedist: {
      value: "orthopedist",
      optionText: "Ortopedista",
    },
    occupationalTherapist: {
      value: "occupationalTherapist",
      optionText: "Terapeuta Ocupacional",
    },
    other: {
      value: "other",
      optionText: "Outro",
    },
  },
  sides: {
    left: "Esquerdo",
    right: "Direito",
    unique: "Único",
  },
  diagnostic: {
    newDiagnosisLabel: "Não encontrou um diagnóstico?",
    diagnosisRegisters: "Registre um",
    newDiagnosisModalLabel: "Registrar Diagnóstico",
    name: "Nome",
    icd: "CID",
    register: "Registrar",
  },
  gender: {
    gender: "Gênero",
    male: { optionText: "Masculino", value: "male" },
    female: { optionText: "Feminino", value: "female" },
  },
};

const evaluation: ITranslateEvaluation = {
  patient: "Paciente",
  name: "Nome",
  age: "Idade",
  months: "Meses",
  weight: "Peso",
  height: "Altura",
  evaluation: "Avaliação",
  diagnosis: "Diagnóstico",
  previousOrthesis: "Uso previo de órtese",
  indication: "Indicação",
  observations: "Observações",
  header: "Avaliação",
  requirements: "Requisitos",
  printer: "Impressora",
  side: "Lado",
  bodyPart: "Parte do corpo",
  segmentaion: "Segmentação",
  injuryType: "Tipo de lesão",
  surgeryDate: "Data da cirurgia",
  urgency: "Urgência",
  urgencyCause: "Justificativa da urgência",
  urgencyNotComprooved: "Urgência não comprovada",
  measure: "Medida",
  planning: "Planejamento",
  professional: "Profissional",
  images: "Imagens",
  files: "Arquivos",
};

const form_errors: ITranslateForm_Errors = {
  alert: "Verifique os campos com erro.",
  required: "${path} O campo é obrigatório",
  minRequired: "${path} Obrigatório",
  matchPassword: "${path} As senhas não coincidem",
  validEmail: "${path} Digite um email válido",
  validCountry: "${path} Selecione um país",
  invalidValue: "${path} Insira um valor válido",
  passwordLength: "${path} A senha deve ter no mínimo 8 caracteres",
  passwordCharacters:
    "${path} A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial",
};

const disabled_user: ITranslateDisabled_user = {
  principal: "Seu acesso está desativado por pendências",
  secundary: "Por favor, entrar em contato com o SAF",
  disabledFixiter: "Ops, seu plano expirou!",
  disabledFixiterSecondary: "Entre em contato com o SAF para renovar",
};

const financial: ITranslateFinancial = {
  header: "Financeiro",
  prices: {
    header: "Preços",
    create: "Criar preço",
    edit: "Editar preço",
    update: "Atualizar",
    save: "Salvar",
    country: {
      default: "TODOS",
    },
    confirm: {
      deactivate: "Tem certeza que quer desativar este preço?",
      activate: "Tem certeza que quer ativar este preço?",
    },
    field: {
      country: "País",
      order_type: "Tipo de pedido",
      solution_type: "Tipo de solução",
      currency: "Moeda",
      file_price: "Preço",
      solution: "Solução",
      plan_name: "Nome do plano",
      trial_period: "Vigência",
      numberOfSolutions: "Qtd. Soluções",
      active: "Ativo",
      inactive: "Inativo",
    },
    solution_type: {
      standard: "Padronizado",
      custom: "Customizada",
      plan: "P. Cirúrgico",
      default: "Todos",
    },
    order_type: {
      is_patient: "B2C",
      is_partner: "B2B",
      is_fixiter: "FIXITER",
    },
    plan_type: {
      demo: "Demo",
      advanced: "Avançado",
      basic: "Básico",
      old_signatures: "Modelos antigos",
    },
  },
  plans: {
    confirm: {
      deactivate: "Tem certeza que quer desativar este plano?",
      activate: "Tem certeza que quer ativar este plano?",
    },
    financial: {
      free: "Tem certeza que deseja marcar o plano como gratuito?",
      paid: "Tem certeza que quer marcar o plano como pago?",
    },
  },
  situation: {
    paid: "Pago",
    not_paid: "Faturado",
    courtesy: "Cortesia",
  },
  error: {
    get_prices: "Ocorreu um erro ao tentar carregar os preços!",
    get_signature:
      "Você ainda não possui uma assinatura, entre em contato com o SAF!",
  },
};

const error: ITranslateErrorMessages = {
  printings: {
    completed: "Impressão já realizada",
    quantity: "Atingiu o limite de impressões",
    missingpart: "Faltando uma parte da impressão, contate o SAF",
  },
};

const printer: ITranslatePrinter = {
  setup: "Clique para configurar",
  ready: "Pronta para impressão",
  erase: "Apagar",
  filament: {
    success: "Configuração de filamento realizada com êxito!",
    error: "Não foi possível realizar a configuração do filamento!",
    filamentConfigs: "Configuração de Filamento",
    filamentDescription:
      "Por favor, insira seus dados de compra do filamento para que possamos calcular correntamente seu custo de material.",
    buyingCoin: "Moeda de Compra",
    buyingCost: "Custo de Compra do Filamento / kg",
    configure:
      "Para exibição dos dados de custo, favor configurar seus dados de filamento.",
  },
};

const courses: ITranslateCourses = {
  names: {
    fixit: "Tecnologia Fix it",
    punhofix: "Treinamento Aplicação PunhoFix",
  },
  descriptions: {
    fixit: "Aprenda mais sobre a Fix it e sobre impressão 3D!",
    punhofix: "Aprenda como aplicar uma das nossas principais soluções!",
  },
};

const address: ITranslateAddress = {
  address_options: {
    address: "Endereço",
    edit: "Editar endereço",
    delete: "Excluir endereço",
    new_address: "Cadastrar endereço",
    create_as: "Cadastrar como:",
    created_as: "Cadastrado como:",
    save: "Salvar",
    att: "Atualizar",
    close: "Fechar",
    cancel: "Cancelar",
  },
  my_addresses: "Meus Endereços",
  billing_address: "ENDEREÇO DE COBRANÇA",
  deliver_address: "ENDEREÇO DE ENTREGA",
  company_address: "ENDEREÇO DA EMPRESA",
  address: "Logradouro",
  cep: "CEP",
  number: "Número",
  complement: "Complemento",
  district: "Bairro",
  city: "Cidade",
  state: "Estado",
};

const person: ITranslatePerson = {
  manager: "Gerente",
  user: "Usuário",
  edit_personal_data: "Editar dados pessoais",
  delete_user: "Apagar dados pessoais",
  name: "Nome",
  cpf: "CPF",
  cellphone: "Celular",
  expertise: "Função",
  city: "Cidade",
  state: "Estado",
  country: "País",
  create_person: "Avançar",
  personal_data: "Dados Pessoais",
  save: "Salvar",
  error: {
    user_registration_error: "Não foi possível cadastrar o usuário! Entre em contato com o SAF.",
    delete_user: "Esse usuário não pode ser deletado!",
  },
  success: {
    att_user_data: "Dados pessoais atualizados com sucesso!",
    user_deleted_success: "Usuário deletado!",
    user_deleted_error: "Não foi possível excluir o usuário!",
  },
  bpRegisterPopover: "Somente para uso de usuários internos BP",
  bpRegister: "Matrícula BP"
};

const coins: ITranslateCoins = {
  paraguai: {
    coin: "Guarani",
    country_name: "Paraguai",
  },
  mozambique: {
    coin: "Metical",
    country_name: "Moçambique",
  },
  uruguai: {
    coin: "Peso Uruguaio",
    country_name: "Uruguaio",
  },
  swiss: {
    coin: "Franco Suíço",
    country_name: "Suíça",
  },
  europe: {
    coin: "Euro",
    country_name: "Europe",
  },
  argentina: {
    coin: "Peso Argentino",
    country_name: "Argentina",
  },
  el_salvador: {
    coin: "Dolar",
    country_name: "El Salvador",
  },
  venezuela: {
    coin: "Peso Venezuelano",
    country_name: "Venezuela",
  },
  brazil: {
    coin: "Real",
    country_name: "Brazil",
  },
  moneySymbol: "R$",
};

const ptBR: ILanguageMessages = {
  translations: {
    transactions,
    language,
    noInfo,
    userRegistration,
    tips,
    login,
    creators,
    global,
    dashboard,
    solutions,
    success,
    orders,
    profile,
    sidebar,
    navMenu,
    topbar,
    dropZone,
    fileList,
    goBackBtn,
    optionsBtn,
    orderBox,
    searchBar,
    surgicalPlanning,
    standardSolution,
    customSolution,
    evaluation,
    form_errors,
    disabled_user,
    financial,
    fixiterOnboarding,
    error,
    printer,
    courses,
    address,
    person,
    coins,
    userProfile,
    bills,
  },
};

const messages = { ptBR };

export { messages };
