import { i18n } from "../../localization/i18n";

export const licenseeOptions = [
  { optionText: `${i18n.t("userProfile.my_profile")}`, value: "my_profile" },
  { optionText: `${i18n.t("userProfile.patientLabel")}`, value: "patients" },
  { optionText: `${i18n.t("userProfile.partnerLabel")}`, value: "partners" },
  { optionText: `${i18n.t("userProfile.addressLabel")}`, value: "address" },
  { optionText: `${i18n.t("userProfile.userLabel")}`, value: "users" },
];

export const licenseeWithoutPrinter = [
  { optionText: `${i18n.t("userProfile.my_profile")}`, value: "my_profile" },
  { optionText: `${i18n.t("userProfile.patientLabel")}`, value: "patients" },
  { optionText: `${i18n.t("userProfile.partnerLabel")}`, value: "partners" },
  { optionText: `${i18n.t("userProfile.addressLabel")}`, value: "address" },
  { optionText: `${i18n.t("userProfile.userLabel")}`, value: "users" },
];

export const currencyValue = {
  BRL: "BRL",
  USD: "USD",
};

export const countryOrderValue = {
  BRA: "BRA",
  default: "USA",
};
