import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { i18n } from "../../../localization/i18n";

import Popover from "../../../components/Popover";
import { ConfirmOrderUrgencyModal } from "../components/ConfirmOrderUrgencyModal";

import { orderStatus, orderUrgencyTypes } from "../../../utils/constants";

import { IOrder } from "../dtos";

import { RiAlarmWarningFill, RiPrinterLine } from "react-icons/ri";

import {
  ActionItem,
  ActionsContainer,
  IconButtonContainer,
} from "../components/OrderItem/styles";
import { CustomOrderUploadForm } from "../components/CustomOrderUploadForm";

interface ActionsProps {
  order: IOrder;
  getOrders: (reload: boolean) => Promise<void>;
  onUpdate: (order: IOrder) => void;
  setOrderPrintManagement: (
    value: React.SetStateAction<IOrder | undefined>
  ) => void;
}

export const CreatorActions = ({
  order,
  getOrders,
  setOrderPrintManagement,
  onUpdate,
}: ActionsProps) => {
  const navigate = useNavigate();

  const [confirmOrderUrgencyModal, setConfirmOrderUrgencyModal] =
    useState(false);

  return (
    <ActionsContainer>
      {order.urgency_type === orderUrgencyTypes.requested &&
        order.urgency_reason && (
          <ActionItem>
            <Popover
              position="left"
              label={`${i18n.t("orders.urgency.popoverRequested")}`}
            >
              <RiAlarmWarningFill
                size={18}
                color="var(--error)"
                onClick={() => setConfirmOrderUrgencyModal(true)}
              />
            </Popover>
          </ActionItem>
        )}

      {order.urgency_type === orderUrgencyTypes.requested &&
        !order.urgency_reason && (
          <ActionItem>
            <Popover
              position="left"
              label={`${i18n.t("orders.urgency.popoverNonJustified")}`}
            >
              <RiAlarmWarningFill size={18} color="var(--error)" />
            </Popover>
          </ActionItem>
        )}

      {order.urgency_type === orderUrgencyTypes.approved && (
        <ActionItem>
          <Popover
            position="left"
            label={`${i18n.t("orders.urgency.popoverApproved")}`}
          >
            <RiAlarmWarningFill size={18} color="var(--error)" />
          </Popover>
        </ActionItem>
      )}

      {order.urgency_type === orderUrgencyTypes.requested && (
        <ConfirmOrderUrgencyModal
          order={order}
          open={confirmOrderUrgencyModal}
          getOrders={getOrders}
          handleCloseModal={() => setConfirmOrderUrgencyModal(false)}
        />
      )}

      {order.solution_type === "standard" && (
        <ActionItem className="print">
          <IconButtonContainer
            disabled={order.status !== orderStatus.completed}
          >
            <RiPrinterLine
              size={18}
              onClick={() => {
                order.status === orderStatus.completed &&
                  setOrderPrintManagement(order);
              }}
            />
          </IconButtonContainer>
        </ActionItem>
      )}

      <ActionItem>
        <CustomOrderUploadForm onUpdate={onUpdate} order={order} />
      </ActionItem>

      {/* <ActionItem>
        <Popover
          position="left"
          label={
            order.status === i18n.t("orders.status.completed.value")
              ? `${i18n.t("creators.addFile")}`
              : `${i18n.t("creators.make")}`
          }
        >
          <IconButtonContainer
            disabled={
              order.solution_type === "standard" ||
              order.status === i18n.t("orders.status.cancelled.value")
            }
          >
            <RiToolsFill
              size={18}
              onClick={async () => {
                if (order.status !== i18n.t("orders.status.completed.value")) {
                  await api.patch(`/orders/${order.id}`, {
                    status: i18n.t("orders.status.development.value"),
                  });
                }

                navigate(
                  "/creators/orders/make", {
                  state: {
                    order_id: order.id,
                    addFile:
                      order.status === i18n.t("orders.status.completed.value"),
                }});
              }}
            />
          </IconButtonContainer>
        </Popover>
      </ActionItem> */}
      {/*<ActionItem>
        <Popover position="left" label={`${i18n.t("orders.view")}`}>
          <IconButtonContainer>
            <RiEyeLine
              size={18}
              onClick={() => {
                navigate("/creators/orders/show", {
                  state: {
                    order_id: order.id,
                  },
                });
              }}
            />
          </IconButtonContainer>
        </Popover>
      </ActionItem>
      */}
    </ActionsContainer>
  );
};
