import { useEffect, useState } from "react";

import { RiFilter3Fill } from "react-icons/ri";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";

import {
  Container,
  FilterContainer,
  SectionHeader,
  SectionName,
  Footer,
  ApplyButton,
  InputLine,
  InputContainer,
  PeriodInputsContainer,
  PeriodInput,
  DatePickerContainer,
  InputSeparator,
  CheckboxContainer,
  CheckboxInput,
} from "./styles";
import { SearchableSelect } from "../../../../components/SearchableSelect";
import { i18n } from "../../../../localization/i18n";
import { showToast } from "../../../../components/CustomToast";
import api, { isAxiosCancel } from "../../../../services/api";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { nullOption, orderTypeOptions, solutionTypeOptions } from "../../mocks";
import {
  IFilter,
  ILicensee,
  IOption,
  IPeriod,
  ISearchableLicensee,
  ISearchablePerson,
  ISearchableSolution,
  ISolution,
  IPerson,
} from "../../dtos";
import { orderStatus } from "../../../../localization/data/mocks";
import { FiChevronDown, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { roles } from "../../../../utils/constants";
import { useAuth } from "../../../../hooks/AuthContext";
import { Checkbox } from "../../../../components/Checkbox";
import { localeMocks } from "../../../licensees/pages/NewDashboard/components/Filter/mocks";
import { getActiveLanguage } from "../../../../utils/getActiveLanguage";
import { OutlinedCheckbox } from "../../../../components/OutlinedCheckbox";

interface FilterProps {
  onChange: (filterData: IFilter) => void;
  filter?: IFilter;
}

export function Filter({ onChange, filter }: FilterProps) {
  const { user } = useAuth();
  const activeLanguage = getActiveLanguage();
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState<ISolution>();
  const [selectedSolutionType, setSelectedSolutionType] = useState<IOption>();
  const [selectedOrderType, setSelectedOrderType] = useState<IOption>();
  const [selectedStatus, setSelectedStatus] = useState<IOption>();
  const [selectedLicensee, setSelectedLicensee] = useState<ILicensee>();
  const [selectedPerson, setSelectedPerson] = useState<IPerson>();
  const [financialStatus, setFinancialStatus] = useState<string>();

  const [filterByDate, setFilterByDate] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState<IPeriod>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const [isPeriod, setIsPeriod] = useState(false);

  const [solutions, setSolutions] = useState<ISearchableSolution[]>([]);
  const [licensees, setLicensees] = useState<ISearchableLicensee[]>([]);
  const [persons, setPersons] = useState<ISearchablePerson[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [solutionSearchValue, setSolutionSearchValue] = useState("");
  const [licenseeSearchValue, setLicenseeSearchValue] = useState("");
  const [personSearchValue, setPersonSearchValue] = useState("");

  function prevDate(e: React.MouseEvent<SVGElement>) {
    e.preventDefault();
    const prev = selectedMonth.setMonth(selectedMonth.getMonth() - 1);
    setSelectedMonth(new Date(prev));
  }

  function nextDate(e: React.MouseEvent<SVGElement>) {
    e.preventDefault();
    const next = selectedMonth.setMonth(selectedMonth.getMonth() + 1);
    setSelectedMonth(new Date(next));
  }

  function applyFilterData() {
    const filterData: IFilter = {
      order_type: selectedOrderType,
      solution_type: selectedSolutionType,
      solution: selectedSolution,
      month: !isPeriod && filterByDate ? selectedMonth : undefined,
      period: isPeriod && filterByDate ? selectedPeriod : undefined,
      status: selectedStatus,
      licensee: selectedLicensee,
      person: selectedPerson,
      financial_status: financialStatus,
    };

    onChange(filterData);

    setFilterOpen(false);
  }

  useEffect(() => {
    setFinancialStatus(filter?.financial_status);
    setSelectedSolution(filter?.solution);
    setSolutionSearchValue(filter?.solution?.name ?? "");
    setSelectedSolutionType(filter?.solution_type);
    setSelectedOrderType(filter?.order_type);
    setSelectedStatus(filter?.status);
    setLicenseeSearchValue(filter?.licensee?.name ?? "");
    setSelectedLicensee(filter?.licensee);
    setSelectedPerson(filter?.person);
    setPersonSearchValue(filter?.person?.name ?? "");
    filter?.month && setSelectedMonth(filter.month);
    filter?.period && setSelectedPeriod(filter.period);
    (filter?.month || filter?.period) && setFilterByDate(true)

    /* if (filter && !filter.month && !filter.period) {
      setIsPeriod(true);
      setSelectedPeriod({
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
      });
    }
*/

  }, [filter]);



  async function searchSolutions(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      // request de profissionais
      const { data } = await api.get(
        `/solutions/search?term=${solutionSearchValue}`,
        { signal }
      );
      setSolutions(
        data.map((p: any) => ({
          id: p.id,
          label: p.name ?? p.company_name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar as soluções.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  async function searchLicensees(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      const { data } = await api.get(
        `/licensees/search?term=${licenseeSearchValue}`,
        { signal }
      );
      setLicensees(
        data.map((p: any) => ({
          id: p.id,
          label: p.name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  async function searchPerson(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      const { data } = await api.get(
        `/person/search?term=${personSearchValue}`,
        { signal }
      );
      setPersons(
        data.map((p: any) => ({
          id: p.id,
          label: p.name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os sublicenciados.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (solutionSearchValue != "") {
      searchSolutions(signal);
    }

    if (licenseeSearchValue != "") {
      searchLicensees(signal);
    }

    if (personSearchValue != "") {
      searchPerson(signal);
    }

    return () => abortController.abort();
  }, [solutionSearchValue, licenseeSearchValue, personSearchValue]);

  return (
    <Container>
      <RiFilter3Fill
        size={34}
        color={filterOpen ? "var(--fixit)" : "var(--gray)"}
        onClick={() => setFilterOpen((prevState) => !prevState)}
      />

      <FilterContainer open={filterOpen}>
        <InputLine>
          <InputContainer>
            <CheckboxInput
              id="filterByMonth"
              type="checkbox"
              checked={filterByDate}
              onClick={() => setFilterByDate(prevState => !prevState)}
            />
            <PeriodInputsContainer className={isPeriod ? "period" : "month"}>
              <SectionHeader onClick={() => {
                setIsPeriod((prevState) => !prevState)
              }}
              >
                <FiChevronDown
                  size={16}
                  color="#9E9E9E"
                />
                <SectionName>
                  {" "}
                  {isPeriod
                    ? `${i18n.t("dashboard.period")}`
                    : `${i18n.t("dashboard.month")}`}
                </SectionName>
              </SectionHeader>
              <InputSeparator></InputSeparator>
              {isPeriod ? (
                <>
                  <PeriodInput>
                    <InputContainer>
                      <DatePicker
                        selected={selectedPeriod.from}
                        onChange={(date: Date) => {
                          date &&
                            setSelectedPeriod((prevState) => ({
                              ...prevState,
                              from: startOfDay(date),
                            }));
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale={localeMocks[activeLanguage ?? "en"]}
                      />
                    </InputContainer>
                  </PeriodInput>
                  <span className="separator"> - </span>
                  <PeriodInput>
                    <InputContainer>
                      <DatePicker
                        selected={selectedPeriod.to}
                        onChange={(date: Date) => {
                          setSelectedPeriod((prevState) => ({
                            ...prevState,
                            to: endOfDay(date),
                          }));
                        }}
                        minDate={selectedPeriod?.from}
                        dateFormat="dd/MM/yyyy"
                        locale={localeMocks[activeLanguage ?? "en"]}
                      />
                    </InputContainer>
                  </PeriodInput>
                </>
              ) : (
                <PeriodInput>
                  <DatePickerContainer>
                    <FiChevronLeft onClick={(e) => prevDate(e)} size={18} />
                    <DatePicker
                      selected={selectedMonth}
                      onChange={(date: Date) => setSelectedMonth(date)}
                      dateFormat="MM/yyyy"
                      locale={localeMocks[activeLanguage ?? "en"]}
                      showMonthYearPicker
                      showTwoColumnMonthYearPicker
                    />
                    <FiChevronRight onClick={(e) => nextDate(e)} size={18} />
                  </DatePickerContainer>
                </PeriodInput>
              )}
            </PeriodInputsContainer>
          </InputContainer>
        </InputLine>
        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("orders.fields.status")}`}
              options={[nullOption, ...orderStatus]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedStatus(undefined)
                  return;
                }

                setSelectedStatus(
                  orderStatus.find((t) => t.value === selected.value)
                );
              }}
              selectedOption={selectedStatus?.optionText || ""}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("financial.prices.field.order_type")}`}
              options={[nullOption, ...orderTypeOptions]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedOrderType(undefined)
                  return;
                }

                setSelectedOrderType(selected)
              }}
              selectedOption={selectedOrderType?.optionText || ""}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={`${i18n.t("financial.prices.field.solution_type")}`}
              options={[nullOption, ...solutionTypeOptions]}
              handleSelect={(selected) => {
                if (selected.value === "") {
                  setSelectedSolutionType(undefined)
                  return;
                }
                setSelectedSolutionType(selected)
              }}
              selectedOption={selectedSolutionType?.optionText || ""}
            />
          </InputContainer>

          <InputContainer>
            <SearchableSelect
              label={`${i18n.t("financial.prices.field.solution")}`}
              options={solutions}
              defaultValue={solutionSearchValue}
              loading={selectLoading}
              onSelect={(selected) => {
                if (typeof selected === "string") {
                  setSelectedSolution(undefined)
                  return;
                }
                setSelectedSolution(solutions?.find((s) => s.model.id === selected.id)?.model)
              }}
              onChange={(value) => {
                setSolutionSearchValue(value);
              }}
            />
          </InputContainer>
        </InputLine>
        {user.role !== roles.licensee &&
          user.role !== roles.fixiter &&
          user.role !== roles.employee ? (
          <InputLine>
            <InputContainer>
              <SearchableSelect
                label={`${i18n.t("profile.unit")}`}
                options={licensees}
                defaultValue={licenseeSearchValue}
                loading={selectLoading}
                onSelect={(selected) => {
                  if (typeof selected === "string") {
                    setSelectedLicensee(undefined)
                    return;
                  }

                  setSelectedLicensee(licensees?.find((s) => s.model.id === selected.id)?.model)
                }}
                onChange={(value) => {
                  setLicenseeSearchValue(value);
                }}
              />
            </InputContainer>
          </InputLine>
        ) : (
          <></>
        )}
        {user.role === roles.licensee ? (
          <InputLine>
            <InputContainer>
              <SearchableSelect
                label={`${i18n.t("orders.filter.sublicensee")}`}
                options={persons}
                defaultValue={personSearchValue}
                loading={selectLoading}
                onSelect={(selected) => {
                  if (typeof selected === "string") {
                    setSelectedPerson(undefined)
                    return;
                  }
                  setSelectedPerson(persons?.find((s) => s.model.id === selected.id)?.model)
                }}
                onChange={(value) => {
                  setPersonSearchValue(value);
                }}
              />
            </InputContainer>
          </InputLine>
        ) : (
          <></>
        )}
        <Footer>
          {user.role === roles.admin && (
            <CheckboxContainer checked={financialStatus === "fixcoin_paid"}>
              <Checkbox
                checked={financialStatus === "fixcoin_paid"}
                handleChange={() => {
                  if (financialStatus) setFinancialStatus(undefined);
                  else if (financialStatus === undefined)
                    setFinancialStatus("fixcoin_paid");
                }}
              />
              <span>Pago com FixCoin</span>
            </CheckboxContainer>
          )}
          <ApplyButton type="button" onClick={applyFilterData}>
            {`${i18n.t("dashboard.apply")}`}
          </ApplyButton>
        </Footer>
      </FilterContainer>
    </Container>
  );
}
