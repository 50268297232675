import { SwitchTabs } from "../../components/SwitchTabs";
import MonthClosure from "./components/MonthClosure";
import { Container } from "./styles";
import { i18n } from "../../localization/i18n";
import { PaymentInfos } from "./components/PaymentInfos";
import UserBills from "./components/UserBills";
import { Transactions } from "./components/Transactions";

interface ISwitchTabs {
  title: string;
  component: any;
}

export function Financial() {
  const financialTabs: ISwitchTabs[] = [
    {
      title: `${i18n.t(`dashboard.header`)}`,
      component: <MonthClosure />
    },
    {
      title: `${i18n.t("bills.bills")}`,
      component: <UserBills />

    },
    {
      title: `${i18n.t("transactions.header")}`,
      component: <Transactions />
    },
    /*{
      title: `${i18n.t(`userProfile.paymentInfos`)}`,
      component: <PaymentInfos />
    },*/
  ]

  return (
    <Container>
      <SwitchTabs tabs={financialTabs} />
    </Container>
  )
}
