import { useEffect, useState } from "react";
import { RiDeleteBin7Line, RiUpload2Line } from "react-icons/ri";
import DropZone from "../../../../components/DropZone";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { i18n } from "../../../../localization/i18n";
import { IOrder } from "../../dtos";
import {
  Container,
  Header,
  LinkButton,
  Subtitle,
  SwitchFormGroup,
  SwitchFormControlLabel,
  Title,
  TitleContainer,
  FixitSwitch,
  Content,
  InputLine,
  InputContainer,
  DropzoneContainer,
  File,
  ProgressBar,
  FileLabel,
  BoxMargin,
  BoxCard,
  TextAreaContainer,
  TextArea
} from "./styles";
import {
  orderStatus,
  Slicers,
  SlicersVariables,
} from "../../../../utils/constants";
import { IOrderUploadData } from "../CustomOrderUploadForm";
import { Loader } from "../../../../components/Loader";
import { showToast } from "../../../../components/CustomToast";
import { SolidButton } from "../../../../components/SolidButton";
import api from "../../../../services/api";

interface IBoxCustomOrderUploadFormParams {
  order: IOrder;
  uploadFase?: string;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  file?: File;
  loading: boolean;
  setFile: (file?: File) => void;
  deleteFile: (id: string) => void;
  submit: () => void;
  close: () => void;
  startDevelopment: (orderId: string) => void;
  callReview: (orderId: string) => Promise<void>;
  commitReview: (orderId: string) => Promise<void>;
  orderCustomUploadData?: IOrderUploadData;
  setOrderCustomUploadData: React.Dispatch<
    React.SetStateAction<IOrderUploadData | undefined>
  >;
  slicer?: string;
  setSlicer: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function BoxCustomOrderUploadForm({
  order,
  file,
  setFile,
  orderCustomUploadData,
  setOrderCustomUploadData,
  slicer,
  setSlicer,
  deleteFile,
  uploadFase,
  progress,
  setProgress,
  submit,
  startDevelopment,
  callReview,
  commitReview,
  close,
  loading,
}: IBoxCustomOrderUploadFormParams) {
  const [emailContent, setEmailContent] = useState("")
  const [sendingEmail, setSendingEmail] = useState(false)

  function getFileData(content: string) {
    const fileData: IOrderUploadData = {
      materialCost: "0.00",
      materialWeight: "0.00",
      printingTime: "00:00",
      slicer: slicer ?? "Unknown",
    };

    if (slicer === Slicers.cura) {
      let hours;
      let arred_minutes;
      let filament;
      let materialCost_cura;

      let time_position = content.indexOf("TIME:");
      let filament_position = content.indexOf("Filament");

      let time = +content.slice(time_position + 5, filament_position - 2);

      filament = +content.slice(filament_position + 15, filament_position + 22);

      let weight_cura =
        filament * SlicersVariables.cura.curaFilamentWheightPerMeter;

      materialCost_cura =
        filament *
        ((SlicersVariables.cura.curaFilamentWheightPerMeter / 1000) *
          SlicersVariables.cura.valuePerKilogramBR);

      fileData.materialWeight = String(weight_cura.toFixed(2));
      fileData.materialCost = String(materialCost_cura.toFixed(2)) + "";

      if (time > 3600) {
        hours = Math.round(time / 3600);
        arred_minutes = Math.trunc((time / 3600 - hours) * 60);
      }

      if (time < 3600) {
        arred_minutes = Math.trunc(time / 60);
      }

      if (hours && hours >= 1) {
        fileData.printingTime = `${hours} hours ${arred_minutes} min`;
      } else {
        fileData.printingTime = `${arred_minutes} min`;
      }
    } else if (slicer === Slicers.prusa) {
      let filament_weight;
      let time;
      let cost;

      let time_position = content.indexOf("time");
      let filament_position = content.indexOf("used [g]");
      let cost_position = content.indexOf("filament cost");

      time = content.slice(time_position + 20, time_position + 27);
      filament_weight = content.slice(
        filament_position + 11,
        filament_position + 16
      );
      cost = content.slice(cost_position + 16, cost_position + 20);

      fileData.printingTime = time;
      fileData.materialWeight = filament_weight;
      fileData.materialCost = cost;
    } else if (slicer === Slicers.simplify) {
      let time_position = content.indexOf("time:");
      let weight_position = content.indexOf("weight:");
      let cost_position = content.indexOf("cost:");

      fileData.printingTime = content.slice(
        time_position + 5,
        time_position + 20
      );
      fileData.materialWeight = content.slice(
        weight_position + 7,
        weight_position + 13
      );
      fileData.materialCost = content.slice(
        cost_position + 5,
        cost_position + 20
      );
    }

    setOrderCustomUploadData(fileData);
  }

  function getFileText(readSlicer: FileReader) {
    const contentSlicer = readSlicer.result?.toString();

    if (!contentSlicer) {
      setSlicer(undefined);
      return;
    }

    let simplifyPosition = contentSlicer.indexOf("Simplify3D(R)");
    if (simplifyPosition !== -1) {
      setSlicer(Slicers.simplify);
      return;
    }

    let curaPosition = contentSlicer.indexOf("Cura_SteamEngine");
    if (curaPosition !== -1) {
      setSlicer(Slicers.cura);
      return;
    }

    let prusaPosition = contentSlicer.indexOf("PrusaSlicer");
    if (prusaPosition !== -1) {
      setSlicer(Slicers.prusa);
      return;
    }

    setSlicer(undefined);
  }

  function getFilePart(fileName: string) {
    return fileName.split('.')[0][fileName.split('.')[0].length - 1];
  }

  async function sendEmail() {
    setSendingEmail(true)
    try {
      await api.post(`orders/review/${order.id}`, { email_content: emailContent })
        .then(() => {
          showToast({
            message: `Email enviado`,
            type: "success"
          })
          close()
        })
    } catch (err) {
      showToast({
        message: `${err}`,
        type: "error"
      })
    }
    setSendingEmail(false)
  }

  useEffect(() => {
    if (file && slicer) {
      let readableFile = new FileReader();

      readableFile.readAsText(file);

      readableFile.onload = function() {
        if (readableFile.result) {
          getFileData(readableFile.result.toString());
        }
      };
    }
  }, [file, slicer]);

  useEffect(() => {
    if (file) {
      setProgress(0);
      let readSlicer = new FileReader();
      readSlicer.onload = function() {
        getFileText(readSlicer);
      };
      readSlicer.readAsText(file.slice(0, 200));
    }
  }, [file]);

  return (
    <>
      <Container>
        <BoxCard>
          <Header>
            <TitleContainer>
              <Title>{`${i18n.t(
                "orders.custom_production_update_title"
              )}`}</Title>
              <Subtitle>{`${i18n.t(
                "orders.custom_production_update_subtitle"
              )}`}</Subtitle>
            </TitleContainer>
            {loading ? (
              <Loader loaderSize={20} />
            ) : (
              <LinkButton
                disabled={
                  !!uploadFase ||
                  !file ||
                  (order.status !== orderStatus.development &&
                    order.status !== orderStatus.completed)
                }
                onClick={() => {
                  submit();
                }}
              >
                <RiUpload2Line size={16} />
                {`${i18n.t("orders.actions.upload")}`}
              </LinkButton>
            )}
          </Header>
          <SwitchFormGroup>
            <SwitchFormControlLabel
              labelPlacement="start"
              control={
                <FixitSwitch
                  disabled={order.status === orderStatus.cancelled}
                  checked={
                    order.status === orderStatus.completed ||
                    order.status === orderStatus.development
                  }
                  onChange={async (
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    if (order.status === orderStatus.processing) {
                      await startDevelopment(order.id);
                      return;
                    }
                    if (
                      order.status === orderStatus.development ||
                      order.status === orderStatus.completed
                    ) {
                      await callReview(order.id);
                      return;
                    }
                    if (order.status === orderStatus.review) {
                      await commitReview(order.id);
                      return;
                    }
                  }}
                />
              }
              label={`${order.status === orderStatus.processing
                ? i18n.t("orders.start_development")
                : order.status === orderStatus.cancelled
                  ? i18n.t("orders.is_cancelled")
                  : order.status === orderStatus.completed ||
                    order.status === orderStatus.development
                    ? i18n.t("orders.call_review")
                    : i18n.t("orders.commit_review")
                }`}
            />
          </SwitchFormGroup>
          {
            <Content>
              {
                order.status === orderStatus.review ?
                  <TextAreaContainer>
                    <span>Email para licenciado:</span>
                    <TextArea placeholder="Pedido em revisão devido à..." readOnly={sendingEmail} rows={3} onChange={(e) => setEmailContent(e.target.value)} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {!sendingEmail ?
                        <SolidButton text={"Enviar"} buttonHeight={30} fontSize={16} disabled={emailContent === ""} onClick={sendEmail} />
                        :
                        <Loader loaderSize={25} />
                      }
                    </div>
                  </TextAreaContainer>
                  :
                  <>
                    {orderCustomUploadData && (
                      <>
                        <InputLine width="320px" wrap={true}>
                          <InputContainer>
                            <OutlinedInput
                              readOnly={true}
                              inputName="slicer"
                              type="text"
                              label={`${i18n.t("orders.slicer")}`}
                              handleChange={(event) => { }}
                              value={orderCustomUploadData.slicer.toString()}
                            />
                          </InputContainer>
                        </InputLine>
                        <InputLine width="320px" wrap={true}>
                          <InputContainer>
                            <OutlinedInput
                              inputName="printing_time"
                              type="text"
                              label={`${i18n.t("orders.printingTime")}`}
                              handleChange={(event) => {
                                setOrderCustomUploadData((prevState) => ({
                                  ...orderCustomUploadData,
                                  printingTime: event ?? "",
                                }));
                              }}
                              value={orderCustomUploadData.printingTime.toString()}
                            />
                          </InputContainer>
                        </InputLine>
                        <InputLine width="320px">
                          <InputContainer>
                            <OutlinedInput
                              inputName="material_weight"
                              type="text"
                              label={`${i18n.t("orders.materialWeight")}`}
                              handleChange={(event) => {
                                setOrderCustomUploadData((prevState) => ({
                                  ...orderCustomUploadData,
                                  materialWeight: event ?? "",
                                  slicer: orderCustomUploadData.slicer,
                                }));
                              }}
                              value={
                                orderCustomUploadData.materialWeight.toString() + "g"
                              }
                            />
                          </InputContainer>
                          <InputContainer>
                            <OutlinedInput
                              inputName="material_cost"
                              type="text"
                              label={`${i18n.t("orders.materialCost")}`}
                              handleChange={(event) => {
                                setOrderCustomUploadData((prevState) => ({
                                  ...orderCustomUploadData,
                                  materialCost: event ?? "",
                                }));
                              }}
                              value={orderCustomUploadData.materialCost.toString()}
                            />
                          </InputContainer>
                        </InputLine>
                      </>
                    )}
                    {
                      <DropzoneContainer>
                        {!file && (
                          <DropZone
                            multiple={false}
                            onUpload={(fileArr) => {
                              if (order.files.length === 0) {
                                setFile(fileArr[0]);
                              } else {
                                order.files.forEach((f: any) => {
                                  if (getFilePart(f.original_name) === getFilePart(fileArr[0].name)) {
                                    showToast({
                                      type: "error",
                                      message: "Já existe um arquivo para essa parte"
                                    })
                                  } else {
                                    setFile(fileArr[0]);
                                  }
                                })
                              }
                            }}
                          />
                        )}
                        {file && (
                          <File>
                            <ProgressBar
                              className={
                                "progress " + (progress === 100 ? "done" : "")
                              }
                              progress={progress}
                            ></ProgressBar>
                            <FileLabel disabled={!!uploadFase}>
                              <span>{file.name}</span>
                              {!progress ? (
                                <RiDeleteBin7Line
                                  onClick={async () => {
                                    setFile(undefined);
                                    setOrderCustomUploadData(undefined)
                                  }}
                                  size={18}
                                />
                              ) : (
                                <span>{progress}%</span>
                              )}
                            </FileLabel>
                          </File>
                        )}
                        {order.files.map((f, i) => (
                          <File key={`order_files-${i}`}>
                            <FileLabel>
                              <span>{f.link?.split("/").pop()}</span>
                              {
                                <RiDeleteBin7Line
                                  onClick={async () => {
                                    deleteFile(f.id);
                                  }}
                                  size={18}
                                />
                              }
                            </FileLabel>
                          </File>
                        ))}
                      </DropzoneContainer>
                    }
                  </>
              }
            </Content>
          }
        </BoxCard>
        <BoxMargin></BoxMargin>
      </Container>
    </>
  );
}
