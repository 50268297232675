import styled from "styled-components";

export const FormContainer = styled.form`
display: flex;
gap: 20px;

h2{
color: var(--dark-gray)
}
`

export const ContentContainer = styled.div`
width: 50%;
`

export const HeaderContainer = styled.div`
  display: flex;
  padding: 0 0 2rem 0;
  color: var(--gray);
  width: 100%;
  justify-content: center;
`

export const InputLine = styled.div`
  display: flex;
  align-items: flex-center;
  justify-content: center;
  gap: 20px;
  margin: 0.8rem 0;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }
`

export const MeasuresInputLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 0.8rem 0;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }

`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const LoaderContainer = styled.div`
display: flex;
width: 100%;
height: 600px;
justify-content: center;
align-items: center;
`

