import styled from "styled-components";
import global from "../../global"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextItem = styled.span`
    font-size: 1.5rem;
    color: var(--light-gray);

    @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    font-size: 1.2rem;
    text-align: center;
  }
`
