import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';

interface ISwitchTabs {
    title: string;
    component: any;
}

interface ITabElements {
    tabs: ISwitchTabs[];
}

export function SwitchTabs({tabs} : ITabElements) {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return(
        <Box sx={{ width: '100%' , typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider',display:'flex', justifyContent:'center'}}>
                    <TabList 
                    textColor="secondary" 
                    indicatorColor="secondary" 
                    onChange={handleChange} 
                    aria-label='lab API tabs example'>
                        {tabs.map((tab,index) => {
                            return (
                                <Tab label= {tab.title} value={index} />
                            )
                        })}
                    </TabList>
                </Box>
                {tabs.map((tab,index) => {
                    return (
                        <TabPanel value= {index}> {tab.component} </TabPanel>
                    )
                })}
            </TabContext>
        </Box>
    )
}