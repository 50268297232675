import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Bill, useAuth } from "../../hooks/AuthContext";

import { i18n } from "../../localization/i18n";

import PatientList from "./components/PatientList";
import PartnerList from "./components/PartnerList";
import Address from "../../modules/Fixiter/pages/Address";
import Users from "../../modules/Fixiter/pages/UsersList";
import { UserProfileOptions } from "../UserProfileOptions";

import { licenseeOptions, licenseeWithoutPrinter } from "./mock";

import {
  OptionsContainer,
  ProfileContent,
  ProfileDescription,
  ProfileOptions,
} from "./styles";
import { usePrinters } from "../../hooks/PrintersContext";

export default function UsersProfile() {
  const { user, licenseeBills } = useAuth();
  const { userHasPrinter } = usePrinters()
  const { options } = useParams() as any;
  const { setNavigationAction } = useAuth();

  const [licenseeBill, setLicenseeBill] = useState<Bill>();

  const navigate = useNavigate();

  const [choosedOption, setChoosedOption] = useState<keyof typeof mock>(
    options ?? "my_profile"
  );

  const mock = {
    address: {
      title: `${i18n.t("userProfile.addresses")}`,
      component: <Address />,
    },
    patients: {
      title: `${i18n.t("userProfile.patients")}`,
      component: <PatientList />,
    },
    partners: {
      title: `${i18n.t("userProfile.partners")}`,
      component: <PartnerList />,
    },
    users: {
      title: `${i18n.t("userProfile.users")}`,
      component: <Users />,
    },
    my_profile: {
      title: `${i18n.t("userProfile.my_profile")}`,
      component: <UserProfileOptions />,
    },
  };

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: mock[choosedOption].title,
    });
    setChoosedOption(options);
  }, [setNavigationAction, choosedOption, options]);

  useEffect(() => {
    setLicenseeBill(
      licenseeBills.find(
        (bill) =>
          bill.referring_period ===
          `${new Date().getMonth()}/${new Date().getFullYear()}`
      )
    );
  }, [licenseeBills, user.person.licensee.bills]);

  return (
    <>
      <ProfileContent>
        <ProfileDescription>{mock[choosedOption].component}</ProfileDescription>
        <OptionsContainer>
          {userHasPrinter
            ? licenseeOptions.map((option) => {
                return (
                  <ProfileOptions
                    onClick={() =>
                      navigate(
                        `/${user.role.toLocaleLowerCase()}/profile/${
                          option.value
                        }`
                      )
                    }
                    selected={option.value === choosedOption}
                    hasNotification={
                      option.value === "closure" && !licenseeBill
                    }
                  >
                    {option.optionText}
                  </ProfileOptions>
                );
              })
            : licenseeWithoutPrinter.map((option) => {
                return (
                  <ProfileOptions
                    onClick={() =>
                      navigate(
                        `/${user.role.toLocaleLowerCase()}/profile/${
                          option.value
                        }`
                      )
                    }
                    selected={option.value === choosedOption}
                  >
                    {option.optionText}
                  </ProfileOptions>
                );
              })}
        </OptionsContainer>
      </ProfileContent>
    </>
  );
}
