import {
  RiBankLine,
  RiDownloadCloud2Line,
  RiFolder3Line,
  RiLineChartLine,
  RiMenuLine,
  RiTrophyLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { useOnboard } from "../../../hooks/OnboardContext";
import { i18n } from "../../../localization/i18n";
import { LinkItem, LinkTitle, RouteButton } from "../styles";
import { usePrinters } from "../../../hooks/PrintersContext";

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const LicenseeMenuList = ({ toggleMenu }: MenuListProps) => {
  const { licensee, planHasExpired } = useOnboard();
  const { userHasPrinter } = usePrinters()

  return (
    <>
      <LinkItem>
        <Link
          to={!planHasExpired ? "/dashboard" : "/dashboard"}
          onClick={toggleMenu}
        >
          {" "}
          {/* Go to Home */}
          <RouteButton>
            <RiLineChartLine size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.reports")}`}</LinkTitle>
        </Link>
      </LinkItem>

      <LinkItem>
        <Link
          to={!planHasExpired ? "/solutions" : "/dashboard"}
          onClick={toggleMenu}
        >
          {" "}
          {/* Go to Solutions */}
          <RouteButton>
            <RiDownloadCloud2Line size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.solutions")}`}</LinkTitle>
        </Link>
      </LinkItem>

      <LinkItem>
        <Link
          to={!planHasExpired ? "/orders" : "/dashboard"}
          onClick={toggleMenu}
        >
          {" "}
          {/* Go to Orders */}
          <RouteButton>
            <RiMenuLine size={26} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.requests")}`}</LinkTitle>
        </Link>
      </LinkItem>

      {userHasPrinter &&
        <LinkItem>
          <Link
            to={!planHasExpired && userHasPrinter ? "/financial" : "/dashboard"}
            onClick={toggleMenu}
          >
            {" "}
            {/* Go to financial */}
            <RouteButton>
              <RiBankLine size={26} color="var(--white)" />
            </RouteButton>
            <LinkTitle>{`${i18n.t("sidebar.financial")}`}</LinkTitle>
          </Link>
        </LinkItem>
      }

      {licensee?.signature[0] && (
        <>
          {!planHasExpired && (
            <LinkItem>
              <a
                href="https://drive.google.com/drive/folders/12ouIWR70G6D1YOIiq4e8nstEZZ_tirVf?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                {/* Go to Drive Folder */}
                <RouteButton>
                  <RiFolder3Line size={26} color="var(--white)" />
                </RouteButton>
                <LinkTitle>{`${i18n.t("navMenu.files")}`}</LinkTitle>
              </a>
            </LinkItem>
          )}

          <LinkItem>
            <Link to={!planHasExpired ? "/success" : ""} onClick={toggleMenu}>
              {" "}
              {/* Go to SAF */}
              <RouteButton>
                <RiTrophyLine size={28} color="var(--white)" />
              </RouteButton>
              <LinkTitle>{`${i18n.t("navMenu.success")}`}</LinkTitle>
            </Link>
          </LinkItem>
        </>
      )}
    </>
  );
};
