import { useState, useEffect, useCallback } from "react";
import ReactLoading from "react-loading";
import { isChrome } from "react-device-detect";
import { Modal } from "../../components/Modal";
import { OrderItem } from "./components/OrderItem";
import api from "../../services/api";
import { showToast } from "../../components/CustomToast";

import {
  Container,
  Header,
  ListContainer,
  PopupContentWrapper,
  Form,
  TagsContainer,
  FilterContainer,
  Tag,
  Footer,
  LoadMore,
  TotalInfo,
  TotalContainer,
  TotalMobileView,
} from "./styles";
import { i18n } from "../../localization/i18n";
import { useAuth } from "../../hooks/AuthContext";
import { RiCloseFill } from "react-icons/ri";
import { Filter } from "./components/Filter";
import {
  IFileChoice,
  IFilter,
  IOrder,
  IPopupData,
  IPrintChoiceFormData,
} from "./dtos";
import { format } from "date-fns";
import PrintHistoryPopUpModal from "../../components/PrintHistoryPopUp";
import { getApiGetOrdersFunctionByRole } from "./service";
import { usePrinters } from "../../hooks/PrintersContext";
import PrintChoose from "./components/PrintChoose";
import DownloadFile from "./components/DownloadFile";
import PrintControl from "./components/PrintControl";

import { IParams } from "./dtos";
import { useLocation, useParams } from "react-router-dom";
import { roles } from "../../utils/constants";
import { PrintManagementPopUpModal } from "../../components/PrintManagementPopUp";
import PlanitUploadingPopUpModal from "./components/PlanitUploadingPopUpModal";
import { BsDownload } from "react-icons/bs";
import { OrdersToXlsFormatFactory } from "./factories/OrdersToXlsFormatFactory";
import { xlsxColumnsByUserRole } from "./mocks";

import { writeFile, utils } from "xlsx";
import CustomPrintControl from "./components/CustomPrintControl";
import Evaluation from "../licensees/pages/Evaluation";
import ShowOrder from "./ShowOrder";

export function Orders() {
  const { user, setNavigationAction, currency } = useAuth();
  const { closure } = useParams<keyof IParams>();
  const location = useLocation();
  const { defaultPrinter, userHasPrinter } = usePrinters();

  const getOrdersService = getApiGetOrdersFunctionByRole(user.role, user.person?.licensee?.printers?.length > 0);

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<IOrder[]>([]);

  const [isNavigatorPopUpOpen, setIsNavigatorPopUpOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [downloadTimer, setDownloadTimer] = useState(0);
  const [printHistoryOrder, setPrintHistoryOrder] = useState<{
    id: string;
    quantity: number;
  }>();
  const [orderForEdition, setOrderForEdition] = useState<{ id: string; }>();

  const [popupData, setPopupData] = useState<IPopupData>();
  const [popupLink, setPopupLink] = useState("");

  const [filter, setFilter] = useState<IFilter>();
  const [totalPage, setTotalPage] = useState(1);
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersValue, setOrdersValue] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [seeDetails, setSeeDetails] = useState(false)
  const [selectedOrderId, setSelectedOrderId] = useState<string>()

  const [fileChoice, setFileChoice] = useState<IFileChoice>();
  const [contentLength, setContentLength] = useState<number>(0);
  const [received, setReceived] = useState<number>(0);

  const [providerSelected, setIsProviderSelected] = useState(false);
  const [orderPrintManagement, setOrderPrintManagement] = useState<IOrder>();

  const [notPaidOrders, setNotPaidOrders] = useState(0);
  const [totalGcode, setTotalGcode] = useState(0);

  const [uploadingPlanit, setUploadingPlanit] = useState<string>();

  const [printHasStarted, setPrintHasStarted] = useState(false);

  function resumePrinting() {
    setReceived(contentLength);
  }

  const cost = popupData?.cost?.toString() || "0";
  const costFormatted = parseFloat(cost).toLocaleString("pt-br", {
    style: "currency",
    currency: currency?.code ?? "BRL",
  });

  function loadMore() {
    setPage(page + 1);
  }

  const proceedPrintChoice = useCallback(
    async (fileChoice: IFileChoice) => {
      if (!fileChoice.printer || !fileChoice.file_streams.length) {
        if (!defaultPrinter?.value) {
          window.alert(i18n.t("orders.printChoose.noprinter"));
          return;
        }

        if (!fileChoice?.side || !fileChoice?.solution_standard?.id) {
          showToast({
            type: "error",
            message: "Dados incompletos",
          });
          return;
        }

        const { data } = await api.patch(`/file-choice/${fileChoice.id}`, {
          printer_id: defaultPrinter.value,
          side: fileChoice.side,
          solution_standard_id: fileChoice.solution_standard.id,
        });

        setFileChoice(data);
        return;
      }
      setFileChoice(fileChoice);
    },
    [defaultPrinter?.value]
  );

  const savePrintChoice = useCallback(
    async (formData: IPrintChoiceFormData) => {
      if (!formData.standard || !formData.side || !formData.printer_id) {
        showToast({
          type: "error",
          message: "Dados incompletos",
        });
        return;
      }

      try {
        const { data } = await api.post("/file-choice", {
          order_id: formData.order_id,
          printer_id: formData.printer_id,
          side: formData.side,
          solution_standard_id: formData.standard.id,
        });
        setFileChoice(data);
      } catch (e: any) {
        e?.response?.data?.message &&
          showToast({
            type: "error",
            message: i18n.t(e.response.data.message),
          });
      }
    },
    []
  );

  const addPrintChoice = useCallback(
    async (formData: IPrintChoiceFormData) => {
      if (
        !formData.standard ||
        !formData.side ||
        (userHasPrinter &&
          !formData.printer_id)
      ) {
        showToast({
          type: "error",
          message: "Dados incompletos",
        });
        return;
      }

      try {
        const { data } = await api.post("/file-choice", {
          order_id: formData.order_id,
          printer_id: formData.printer_id,
          side: formData.side,
          solution_standard_id: formData.standard.id,
        });
        return data;
      } catch (e: any) {
        e?.response?.data?.message &&
          showToast({
            type: "error",
            message: i18n.t(e.response.data.message),
          });
      }
    },
    [userHasPrinter]
  );

  const getOrders = useCallback(
    async (reload: boolean) => {
      setLoading(true);

      const queryList = [];

      if (filter?.month !== undefined) {
        const [month, year] = format(filter?.month, "MM/yyyy").split("/");
        queryList.push(`month=${month}`);
        queryList.push(`year=${year}`);
      }

      filter?.period?.from !== undefined &&
        queryList.push(`from=${filter?.period?.from.toJSON()}`);
      filter?.period?.to !== undefined &&
        queryList.push(`to=${filter?.period?.to?.toJSON()}`);
      filter?.order_type &&
        queryList.push(`order_type=${filter.order_type.value}`);
      filter?.solution_type &&
        queryList.push(`solution_type=${filter.solution_type.value}`);
      filter?.solution && queryList.push(`solution=${filter.solution.id}`);
      filter?.status && queryList.push(`status=${filter.status.value}`);
      queryList.push(`limit=${limit}`);
      // queryList.push(`as_provider=${!!as_provider}`);
      filter?.licensee && queryList.push(`licensee=${filter.licensee.id}`);
      filter?.person && queryList.push(`personUserId=${filter.person.id}`);
      filter?.financial_status &&
        queryList.push(`financial_status=${filter.financial_status}`);

      !reload && queryList.push(`page=${page}`);

      try {
        const data = await getOrdersService({ queryList });
        setTotalPage(data.total / limit);
        setOrders(reload ? data.data : [...orders, ...data.data]);
        setOrdersCount(data.total);
        setOrdersValue(data.sum);
        setNotPaidOrders(data.totalNotPaid);
        setTotalGcode(data.totalGcode);
      } catch (error) {
        showToast({
          type: "error",
          message: "Ocorreu um erro ao tentar carregar os pedidos",
        });
      } finally {
        setLoading(false);
      }
    },
    [
      filter?.financial_status,
      filter?.licensee,
      filter?.month,
      filter?.order_type,
      filter?.period?.from,
      filter?.period?.to,
      filter?.person,
      filter?.solution,
      filter?.solution_type,
      filter?.status,
      getOrdersService,
      limit,
      orders,
      page,
    ]
  );

  const getOrdersForDownload = useCallback(async (): Promise<IOrder[]> => {
    const queryList = [];

    if (filter?.month !== undefined) {
      const [month, year] = format(filter?.month, "MM/yyyy").split("/");
      queryList.push(`month=${month}`);
      queryList.push(`year=${year}`);
    }

    filter?.period?.from !== undefined &&
      queryList.push(`from=${filter?.period?.from.toJSON()}`);
    filter?.period?.to !== undefined &&
      queryList.push(`to=${filter?.period?.to?.toJSON()}`);
    filter?.order_type &&
      queryList.push(`order_type=${filter.order_type.value}`);
    filter?.solution_type &&
      queryList.push(`solution_type=${filter.solution_type.value}`);
    filter?.solution && queryList.push(`solution=${filter.solution.id}`);
    filter?.status && queryList.push(`status=${filter.status.value}`);
    queryList.push(`limit=${ordersCount}`);
    // queryList.push(`as_provider=${!!as_provider}`);
    filter?.licensee && queryList.push(`licensee=${filter.licensee.id}`);
    filter?.person && queryList.push(`personUserId=${filter.person.id}`);
    filter?.financial_status &&
      queryList.push(`financial_status=${filter.financial_status}`);

    queryList.push(`page=${page}`);

    try {
      var data = await getOrdersService({ queryList });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel realizar download",
      });
    }

    return data.data;
  }, [
    filter?.financial_status,
    filter?.licensee,
    filter?.month,
    filter?.order_type,
    filter?.period?.from,
    filter?.period?.to,
    filter?.person,
    filter?.solution,
    filter?.solution_type,
    filter?.status,
    getOrdersService,
    ordersCount,
    page,
  ]);

  async function downloadOrders() {
    const allOrdersFromSelectedPeriod = await getOrdersForDownload();

    const xlsData = new OrdersToXlsFormatFactory({
      orders: allOrdersFromSelectedPeriod,
      role: user.role,
    }).formatJsonToXls();

    const workSheet = utils.json_to_sheet(xlsData);
    const workBook = utils.book_new();

    const workSheetName = filter?.month
      ? format(filter?.month, "MM.yyyy")
      : filter?.period?.from && filter.period.to
        ? `${format(filter.period.from, "dd.MM.yyyy")} - ${format(
          filter.period.to,
          "dd.MM.yyyy"
        )}`
        : "Fix it";

    utils.book_append_sheet(workBook, workSheet, workSheetName);

    utils.sheet_add_aoa(
      workSheet,
      xlsxColumnsByUserRole[
      user.role !== roles.fixiter && user.role !== roles.employee
        ? "DEFAULT"
        : (user.role as keyof typeof xlsxColumnsByUserRole)
      ],
      {
        origin: "A1",
      }
    );

    writeFile(workBook, `${i18n.t("sidebar.requests")}.xlsx`);
  }

  const openPrintHistory = useCallback(
    ({ orderId, quantity }: { orderId: string; quantity: number }) => {
      setPrintHistoryOrder({
        id: orderId,
        quantity,
      });
    },
    []
  );

  const openOrderEdit = useCallback(({ orderId }: { orderId: string }) => {
    setOrderForEdition({
      id: orderId,
    })
  }, [])

  useEffect(() => {
    if (downloadTimer > 0) {
      setTimeout(() => setDownloadTimer(downloadTimer - 1), 1000);
    }

    return () => {
      setDownloadTimer(0);
    };
  }, [downloadTimer]);

  const printOrderPopup = useCallback(
    (order: any) => {
      if (
        !defaultPrinter &&
        (userHasPrinter)
      ) {
        window.alert(i18n.t("orders.printChoose.noprinter"));
        return;
      }
      setPopupData({
        order,
        type: "print",
        printer: defaultPrinter
          ? {
            id: defaultPrinter.value,
            name: defaultPrinter.name,
            xyz: defaultPrinter.xyz,
          }
          : undefined,
      });
      setIsPopupOpen(true);
    },
    [defaultPrinter, userHasPrinter]
  );

  // aqui
  const printFilePopup = useCallback(
    ({ order, printTime, cost, weight, printer, type }: IPopupData) => {
      setDownloadTimer(5);
      setPopupData({
        order,
        type,
        printTime,
        weight,
        cost,
        printer,
      });
      // opcao 1
      if (isChrome === true) {
        setIsPopupOpen(true);
      } else {
        setIsNavigatorPopUpOpen(true);
      }
    },
    []
  );

  function formatDate(date: Date) {
    return format(date, "dd/MM/yyyy");
  }

  const openPlanitFileUploadPopup = useCallback((planit_id: string) => {
    setUploadingPlanit(planit_id);
  }, []);

  const openPopup = useCallback(
    ({ order, printTime, cost, weight, link, printer, type }: IPopupData) => {
      if (!link) {
        return;
      }
      setDownloadTimer(5);
      setPopupData({
        order,
        type,
        printTime,
        weight,
        cost,
        printer,
      });
      setIsPopupOpen(true);
      setPopupLink(link);
    },
    []
  );

  useEffect(() => {
    // é possível testar pelo page == 1 ao inves de uma flag reload, então tirar o if (page > 1) e aqui apenas fazer o set Page.
    if (!!filter) {
      setPage(1);
      getOrders(true);
    }
  }, [filter]);

  useEffect(() => {
    if (page > 1) {
      getOrders(false);
    }
  }, [page]);

  useEffect(() => {
    if (closure && location.search?.length) {
      const queryParams = new URLSearchParams(location.search);
      const closureFilterFrom = queryParams.getAll("from")[0].split("/");
      const closureFilterTo = queryParams.getAll("to")[0].split("/");
      setFilter((prevState) => ({
        ...prevState,
        month: undefined,
        period: {
          from: new Date(
            parseInt(closureFilterFrom[2]),
            parseInt(closureFilterFrom[1]) - 1,
            parseInt(closureFilterFrom[0])
          ),
          to: new Date(
            parseInt(closureFilterTo[2]),
            parseInt(closureFilterTo[1]) - 1,
            parseInt(closureFilterTo[0])
          ),
        },
      }));
    } else {
      setFilter((prevState) => ({
        ...prevState,
        month: undefined,
        period: undefined,
      }));
    }

    return () => {
      setFilter({});
    };
  }, [location.search, closure]);

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("orders.header"),
    });
  }, [setNavigationAction, seeDetails]);

  return (
    <>
      {seeDetails ?
        <>
          {
            (user.role === roles.licensee || user.role === roles.employee || user.role === roles.fixiter) ?
              <Evaluation
                toggleSeeDetails={() => {
                  setSeeDetails((prevState) => !prevState)
                }}
                order_id={selectedOrderId ?? ""} />
              :
              <ShowOrder
                order_id={selectedOrderId ?? ""}
                toggleSeeDetails={() => {
                  setSeeDetails((prevState) => !prevState)
                }}

              />
          }
        </>
        :
        <>
          {!!uploadingPlanit && (
            <PlanitUploadingPopUpModal
              open={!!uploadingPlanit}
              planit_id={uploadingPlanit}
              close={() => setUploadingPlanit(undefined)}
            />
          )}
          {!!orderPrintManagement && (
            <PrintManagementPopUpModal
              inheritOverflow
              order={orderPrintManagement}
              isOpen={!!orderPrintManagement}
              onRequestClose={() => {
                setOrderPrintManagement(undefined);
              }}
            />
          )}
          {!!printHistoryOrder && (
            <PrintHistoryPopUpModal
              inheritOverflow
              quantity={printHistoryOrder.quantity}
              orderId={printHistoryOrder.id}
              isOpen={!!printHistoryOrder}
              onRequestClose={() => {
                setPrintHistoryOrder(undefined);
              }}
            />
          )}
          {isPopupOpen && popupData && (
            <Modal
              inheritOverflow
              open={isPopupOpen}
              onRequestClose={() => {
                if (printHasStarted) {
                  showToast({
                    type: "info",
                    message: `Bloqueado enquanto a operação está em progresso`,
                  });
                  return;
                }
                setFileChoice(undefined);
                setIsPopupOpen(false);
                getOrders(true);
              }}
            >
              <PopupContentWrapper>
                {popupData.type === "download" ? (
                  <DownloadFile
                    popupData={popupData}
                    popupLink={popupLink}
                    costFormatted={costFormatted}
                    downloadTimer={downloadTimer}
                  />
                ) : !fileChoice ? (
                  <PrintChoose
                    order={popupData.order}
                    printer={popupData.printer}
                    onProceed={proceedPrintChoice}
                    onSelect={savePrintChoice}
                    onAdd={addPrintChoice}
                  />
                ) : !fileChoice.solution_standard ? (
                  <CustomPrintControl
                    fileChoice={fileChoice}
                    setPrintHasStarted={(value) => setPrintHasStarted(value)}
                  />
                ) : (
                  <PrintControl
                    fileChoice={fileChoice}
                    setPrintHasStarted={(value) => setPrintHasStarted(value)}
                  />
                )}
              </PopupContentWrapper>
            </Modal>
          )}
          <Container>
            <Header>
              <TotalContainer>
                <div className="StandardTotalInfo">
                  <TotalMobileView>
                    <TotalInfo>
                      <span className="label">{`${i18n.t(
                        `orders.header`
                      )}`}</span>
                      <span className="value">{ordersCount}</span>
                    </TotalInfo>
                    <TotalInfo>
                      <span className="label">
                        {`${i18n.t(
                          `sidebar.files`
                        )}`}
                      </span>
                      <span className="value">{totalGcode}</span>
                    </TotalInfo>
                    {(!userHasPrinter && user.role !== roles.admin) || user.role === roles.employee ? null : (
                      <>
                        <TotalInfo>
                          <span className="label">Total</span>
                          <span className="value">
                            {parseFloat(notPaidOrders.toString()).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: currency?.code ?? "BRL",
                              }
                            )}
                          </span>
                        </TotalInfo>
                      </>
                    )}
                  </TotalMobileView>
                </div>
              </TotalContainer>
              <Form>
                <TagsContainer>
                  {filter?.status && (
                    <Tag key="status">
                      <span>
                        {`${i18n.t(
                          `orders.status.${filter?.status.value}.optionText`
                        )}`}
                      </span>
                      <RiCloseFill
                        size={14}
                        onClick={() => {
                          setFilter((prevState) => ({
                            ...prevState,
                            status: undefined,
                          }))
                        }}
                      />
                    </Tag>
                  )}
                  {filter?.order_type && (
                    <Tag key="order_type">
                      <span>
                        {`${i18n.t(
                          `financial.prices.order_type.${filter?.order_type.value}`
                        )}`}
                      </span>
                      <RiCloseFill
                        size={14}
                        onClick={() => {
                          setFilter((prevState) => ({
                            ...prevState,
                            order_type: undefined,
                          }))
                        }}
                      />
                    </Tag>
                  )}
                  {filter?.solution && (
                    <Tag key="solution">
                      <span>{filter.solution.name}</span>
                      <RiCloseFill
                        size={14}
                        onClick={() =>
                          setFilter((prevState) => ({
                            ...prevState,
                            solution: undefined,
                          }))
                        }
                      />
                    </Tag>
                  )}
                  {filter?.solution_type && (
                    <Tag key="solution_type">
                      <span>
                        {`${i18n.t(
                          `financial.prices.solution_type.${filter?.solution_type.value}`
                        )}`}
                      </span>
                      <RiCloseFill
                        size={14}
                        onClick={() => {
                          setFilter((prevState) => ({
                            ...prevState,
                            solution_type: undefined,
                          }))
                        }}
                      />
                    </Tag>
                  )}
                  {filter?.licensee && (
                    <Tag key="licensee">
                      <span>{filter.licensee.name}</span>
                      <RiCloseFill
                        size={14}
                        onClick={() =>
                          setFilter((prevState) => ({
                            ...prevState,
                            licensee: undefined,
                          }))
                        }
                      />
                    </Tag>
                  )}
                  {filter?.person && (
                    <Tag key="person">
                      <span>{filter.person.name}</span>
                      <RiCloseFill
                        size={14}
                        onClick={() =>
                          setFilter((prevState) => ({
                            ...prevState,
                            person: undefined,
                          }))
                        }
                      />
                    </Tag>
                  )}
                  {filter?.month && (
                    <Tag>
                      <span>{format(new Date(filter?.month), "MM/yyyy")}</span>
                      <RiCloseFill
                        size={14}
                        onClick={() => {
                          setFilter((prevState) => ({
                            ...prevState,
                            month: undefined
                          }));
                        }}
                      />
                    </Tag>
                  )}
                  {filter?.period && (
                    <Tag>
                      <span>
                        {formatDate(filter.period.from)} -{" "}
                        {formatDate(filter.period.to)}
                      </span>
                      <RiCloseFill
                        size={14}
                        onClick={() => {
                          setFilter((prevState) => ({
                            ...prevState,
                            period: undefined
                          }));
                        }}
                      />
                    </Tag>
                  )}
                  {filter?.financial_status && (
                    <Tag key="financial_status">
                      <span>FixCoin</span>
                      <RiCloseFill
                        size={14}
                        onClick={() =>
                          setFilter((prevState) => ({
                            ...prevState,
                            financial_status: undefined,
                          }))
                        }
                      />
                    </Tag>
                  )}
                </TagsContainer>

                <BsDownload
                  size={25}
                  color="var(--gray)"
                  cursor="pointer"
                  onClick={downloadOrders}
                />

                {/* {userHasPrinter && (
              <Popover
                position="left"
                label={`${
                  as_provider
                    ? i18n.t("orders.as_provider")
                    : i18n.t("userProfile.ordersMade")
                }`}
              >
                <Switch
                  onChange={(event) => {
                    event.target.checked
                      ? navigate({
                          pathname: `/orders/as_provider`,
                        })
                      : navigate({
                          pathname: `/orders`,
                        });
                  }}
                  checkedIcon={
                    <SwitchBackground>
                      <FiUsers color="var(--fixit)" size="14px" />
                    </SwitchBackground>
                  }
                  icon={
                    <SwitchBackground>
                      <FiUser color="var(--gray)" size="14px" />
                    </SwitchBackground>
                  }
                  color="secondary"
                />
              </Popover>
            )} */}

                <FilterContainer>
                  <Filter
                    onChange={(filterData) => {
                      setFilter(filterData);
                    }}
                    filter={filter}
                  />
                </FilterContainer>
              </Form>
            </Header>

            <ListContainer>
              {orders &&
                orders.map((order) => {
                  return (
                    <OrderItem
                      seeDetails={() => {
                        setSeeDetails((prevState) => !prevState)
                      }}
                      setSelectedOrderId={(order_id: string) => {
                        setSelectedOrderId(order_id)
                      }}
                      onUpdate={(updatedOrder) => {
                        setOrders((prevState) => {
                          return prevState.map((p) => {
                            if (p.id === updatedOrder.id) {
                              return updatedOrder;
                            }

                            return p;
                          });
                        });
                      }}
                      getOrders={getOrders}
                      key={order.id}
                      order={order}
                      setIsProviderSelected={setIsProviderSelected}
                      setOrderPrintManagement={setOrderPrintManagement}
                      openPopup={openPopup}
                      printOrder={printOrderPopup}
                      printFile={printFilePopup}
                      openPrintHistory={openPrintHistory}
                      orderEdition={openOrderEdit}
                      openPlanitFileUploadPopup={openPlanitFileUploadPopup}
                    >
                      <></>
                    </OrderItem>
                  );
                })}
            </ListContainer>

            <Footer>
              {loading ? (
                <ReactLoading
                  type={"bubbles"}
                  color={"var(--fixit)"}
                  height={64}
                  width={64}
                />
              ) : page < totalPage ? (
                <LoadMore onClick={loadMore}>Carregar mais...</LoadMore>
              ) : (
                ""
              )}
            </Footer>
          </Container>
        </>
      }
    </>
  );
}
