import { useRoutes } from "react-router-dom";
import SafDashboard from "../../modules/SAF/pages/Dashboard";
import NotFound from "./NotFound";
import { Orders } from "../../modules/Orders";
import Layout from "../../components/Layout";
import { LicenseesList } from "../../modules/Licensee";
import EditLicensee from "../../modules/Licensee/pages/EditLicensee";
import Configurations from "../../modules/SAF/pages/Configurations";
import DiagnosisList from "../../modules/Admin/pages/Diagnosis";

function SafRoutes() {
  const safRoutes = useRoutes([
    {
      path: '/saf/dashboard',
      element: (
        <Layout>
          <SafDashboard />
        </Layout>
      )
    },
    {
      path: '/saf/orders',
      element: (
        <Layout>
          <Orders />
        </Layout>
      )
    },
    {
      path: '/saf/licensees',
      element: (
        <Layout>
          <LicenseesList />
        </Layout>
      )
    },
    {
      path: '/saf/edit/licensee',
      element: (
        <Layout>
          <EditLicensee />
        </Layout>
      )
    },
    {
      path: '/saf/configs',
      element: (
        <Layout>
          <Configurations />
        </Layout>
      )
    },
    {
      path: '/saf/configs/diagnosis',
      element: (
        <Layout>
          <DiagnosisList />
        </Layout>
      )
    },
    {
      path: '/saf/*',
      element: (
        <NotFound />
      )
    },
  ])

  return safRoutes;
}

export default SafRoutes;
