import { useRoutes } from "react-router-dom";
import BracingTypes from "../../modules/Employee/pages/BracingTypes";
import { CreateOrder } from "../../modules/Orders/CreateOrder";
import Dashboard from "../../modules/Employee/pages/Dashboard";
import Layout from "../../components/Layout";
import { Orders } from "../../modules/Orders";
import { SolutionsCategory } from "../../modules/Employee/pages/SolutionsCategory";
import Solutions from "../../modules/Orders/Solutions";
import Address from "../../modules/Employee/pages/Address";
import { useAuth } from "../../hooks/AuthContext";
import { useOnboard } from "../../hooks/OnboardContext";
import LayoutIframe from "../../components/LayoutIframe";
import PlanExpired from "../../modules/Fixiter/pages/PlanExpired";
import Payment from "../../modules/licensees/pages/Payment";
import Logon from "../../modules/Logon";

function EmployeeRoutes() {
  const { user } = useAuth()
  const { planHasExpired } = useOnboard()

  const employeeBlockedRoles = useRoutes([
    { path: "/", element: <Logon /> },
    {
      path: "employee/payment",
      element: (
        <LayoutIframe>
          <Payment />
        </LayoutIframe>
      ),
    },
    {
      path: "employee/dashboard",
      element: (
        <LayoutIframe>
          <PlanExpired />
        </LayoutIframe>
      ),
    },
  ]);


  const employeeRoutes = useRoutes([
    {
      path: "/employee/dashboard",
      element: (
        <Layout>
          <Dashboard />
        </Layout>
      ),
    },
    {
      path: "/employee/solutions",
      element: (
        <Layout>
          <SolutionsCategory />
        </Layout>
      ),
    },
    {
      path: "/employee/dashboard",
      element: (
        <Layout>
          <Dashboard />
        </Layout>
      ),
    },
    {
      path: "/employee/categories/:field_key",
      element: (
        <Layout>
          <BracingTypes />
        </Layout>
      ),
    },
    {
      path: "/employee/solutions/:category_key",
      element: (
        <Layout>
          <Solutions />
        </Layout>
      ),
    },
    {
      path: "/employee/order/:solution_key/:solution_type",
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
      ),
    },
    {
      path: "/employee/order/:solution_key/:solution_type/:size",
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
      ),
    },
    {
      path: "/employee/orders",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
    {
      path: "/employee/address",
      element: (
        <Layout>
          <Address />
        </Layout>
      ),
    },
  ]);

  if (user?.person?.licensee?.is_blocked === true || planHasExpired === true) {
    return employeeBlockedRoles;
  }


  return employeeRoutes;
}

export default EmployeeRoutes;
