import { useEffect, useState } from "react";
import { format } from "date-fns";

import * as Yup from "yup";
import { i18n } from "../../../../localization/i18n";

import {
  Container,
  EvaluationWrapper,
  Section,
  FlexButtons,
  Header,
  TagsContainer,
  Tag,
  FileContainer,
  File,
  DefaultInput,
  DefaultSelect,
  InputContainer,
  IconContainer,
  IconContent,
  Metrics
} from "./styles";
import api from "../../../../services/api";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useAuth } from "../../../../hooks/AuthContext";
import { showToast } from "../../../../components/CustomToast";
import {
  getActiveLanguage,
} from "../../../../utils/getActiveLanguage";
import { orderStatus, orderUrgencyTypes, solutionTypes } from "../../../../utils/constants";
import { IOrder } from "../../../Orders/dtos";
import { Loader } from "../../../../components/Loader";
import { LoaderContainer } from "../../../Orders/components/EditOrderModal/styles";
import { IExamData, IPatientData } from "../../../Orders/CreateOrder/dtos";
import { MdEdit, MdOutlineCancel } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import validateFormFields from "../../../../utils/validateFormFields";
import { usePrinters } from "../../../../hooks/PrintersContext";

export interface IEvaluation {
  order_id: string;
  toggleSeeDetails: () => void;
}

interface ITmpOrderData {
  printer: string;
  patient: IPatientData,
  exam: IExamData
}

interface IField {
  propName: string;
  name: string;
  data: any;
  metrics?: string;
}

interface ISection {
  editable: boolean;
  fieldName: string;
  title: string;
  fields: IField[];
}

export default function Evaluation({ order_id, toggleSeeDetails }: IEvaluation) {
  const { setNavigationAction, user } = useAuth();
  const { userHasPrinter } = usePrinters();

  const [tmpOrderData, setTmpOrderData] = useState<ITmpOrderData>({} as ITmpOrderData)

  const [order, setOrder] = useState<IOrder>({} as IOrder);

  const [loading, setLoading] = useState(false)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [editing, setEditing] = useState(false)

  const [errors, setErrors] = useState({} as any)

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy");
  }

  const updateOrderDetails = async () => {

    setLoadingRequest(true)

    const body = {
      printer_id: tmpOrderData.printer,
      exam: {
        ...tmpOrderData.exam,
        measures: tmpOrderData?.exam?.measures?.map((item: string) => item?.replaceAll(",", ".")),
        side: tmpOrderData.exam?.side?.value,
      },
      patient: {
        ...tmpOrderData.patient,
      }
    }

    const yup_validation = Yup.object().shape({
      printer_id: Yup.string()
        .trim()
        .notRequired()
        .test("not-empty", "printer_id is empty", (value) => value === undefined || value?.trim() !== ""),
      exam: Yup.object()
        .shape({
          measures: Yup.array()
            .of(
              Yup.string()
            )
            .length(order.exam?.measures?.length ?? 0, "measures exact number of items required"),
          side: Yup.string()
            .notRequired()
            .test(
              "not-empty",
              "side is empty",
              (value) => value === undefined || value.trim() !== ""
            )
            .matches(/^[a-zA-Z]+$/, "side must contain letters only"),
        })
        .notRequired()
        .when("$order.exam", {
          is: (exam: any) => !!exam,
          then: (schema: { required: (arg0: string) => any; }) => schema.required("exam is required"),
          otherwise: (schema: { notRequired: () => any; }) => schema.notRequired(),
        }),
      patient: Yup.object()
        .shape({
          name: Yup.string()
            .trim()
            .notRequired()
            .test("not-empty", "name is empty", (value) => value === undefined || value?.trim() !== ""),
          age: Yup.string()
            .matches(/^\d+$/, "age must be a number")
            .notRequired()
            .test("not-zero", "age cannot be zero", (value) => value === undefined || Number(value) > 0),
          months: Yup.string()
            .matches(/^\d+$/, "months must be a number")
            .notRequired()
            .test("not-zero", "months cannot be zero", (value) => value === undefined || Number(value) > 0),
          weight: Yup.string()
            .matches(/^\d+(\.\d+)?$/, "weight must be a number")
            .notRequired()
            .test("not-zero", "weight cannot be zero", (value) => value === undefined || Number(value) > 0),
          height: Yup.string()
            .matches(/^\d+(\.\d+)?$/, "height must be a number")
            .notRequired()
            .test("not-zero", "height cannot be zero", (value) => value === undefined || Number(value) > 0),
          responsible: Yup.string()
            .trim()
            .notRequired()
            .test("not-empty", "responsible is empty", (value) => value === undefined || value?.trim() !== ""),
          phone: Yup.string()
            .trim()
            .notRequired()
            .test("not-empty", "phone is empty", (value) => value === undefined || value?.trim() !== ""),
          email: Yup.string()
            .trim()
            .notRequired()
            .test("not-empty", "email is empty", (value) => value === undefined || value?.trim() !== "")
            .email("email incorrect format"),
        })
        .notRequired(),
    });

    try {
      const validation = await validateFormFields(body, yup_validation, {
        context: { order },
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        setLoadingRequest(false)
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        return;
      }

      setErrors({})

      await api.patch(`/orders/update/${order.id}`, body)
        .then(() => {
          getOrder(order.id)
          setTmpOrderData({} as ITmpOrderData)
        })

      showToast({
        message: "Pedido editado com sucesso",
        type: "success"
      })
    }
    catch (error) {
      console.log(error)
      showToast({
        message: "Não foi possivel editar o pedido",
        type: "error"
      })
    }

    setLoadingRequest(false)
    setEditing(false)
  }


  const deletePlanitFile = async (planit_id: string, file: string) => {
    try {
      await api.delete(`/planits/${planit_id}/files?file=${encodeURI(file)}`)
      getOrder(order_id)
    } catch (error) {
      showToast({ type: "error", message: `${error}` });
    }
  }

  function formatDiagnosis() {
    const diagnosis: any[] = [];

    order?.exam?.diagnosis_list?.forEach((item) => {
      diagnosis.push({
        name: `${item.name[getActiveLanguage()]} - ${item.cid}`,
        description: item.description[getActiveLanguage()],
      });
    });

    if (order.exam?.diagnosis) {
      diagnosis.push({
        name: order.exam?.diagnosis,
      });
    }

    return diagnosis;
  }

  const mock: ISection[] = [
    {
      editable: true,
      fieldName: 'patient',
      title: i18n.t('orders.patient'),
      fields: [
        {
          propName: 'name',
          name: i18n.t('evaluation.name'),
          data: order.patient
            ? order.patient.name
            : order.planit?.patient_name,
        },
        {
          propName: 'age',
          name: i18n.t('evaluation.age'),
          data: order.patient
            ? order.patient.age
            : order.planit?.age,
        },
        {
          propName: 'months',
          name: i18n.t('evaluation.months'),
          data: null
        },
        {
          propName: 'weight',
          name: i18n.t('evaluation.weight'),
          data: `${order.patient
            ? order.patient.weight
            : order.planit?.weight
            }`,
          metrics: 'kg'
        },
        {
          propName: 'height',
          name: i18n.t('evaluation.height'),
          data: `${order.patient
            ? order.patient.height
            : order.planit?.height
            }`,
          metrics: 'cm'
        },
        {
          propName: 'phone',
          name: i18n.t('profile.phone'),
          data: `${order.patient?.phone ?? i18n.t('orders.patientNotSet')}`,
        },
        {
          propName: 'email',
          name: i18n.t('profile.email'),
          data: `${order.patient?.email ?? i18n.t('orders.patientNotSet')}`,
        }
      ],
    },
    order.exam
      ? {
        editable: false,
        fieldName: 'exam',
        title: i18n.t('evaluation.evaluation'),
        fields: [
          {
            propName: 'diagnosis',
            name: i18n.t('evaluation.diagnosis'),
            data: formatDiagnosis(),
          },
          {
            propName: 'previousOrthesis',
            name: i18n.t('evaluation.previousOrthesis'),
            data:
              order.exam &&
              order.exam.prior_use != null &&
              (order.exam.prior_use ? "Sim" : "Não"),
          },
          {
            propName: 'indication',
            name: i18n.t('evaluation.indication'),
            data: (
              order.exam
                ? order.exam.any_indication
                : order.planit?.any_indication
            )
              ? "Sim"
              : "Não",
          }
        ],
      }
      : {
        editable: false,
        fieldName: 'planit',
        title: i18n.t('evaluation.planning'),
        fields: [
          {
            propName: 'diagnosis',
            name: i18n.t('evaluation.diagnosis'),
            data: order.planit?.injury_type
          },
          {
            propName: 'professional',
            name: i18n.t('evaluation.professional'),
            data: `${order.planit?.doctor_name} - ${order.planit?.doctor_contact}`,
          },
          {
            propName: 'bodyPart',
            name: i18n.t('evaluation.bodyPart'),
            data: order.planit?.body_part
          },
          {
            propName: 'segmentation',
            name: i18n.t('evaluation.segmentaion'),
            data: order.planit?.target
          },
          {
            propName: 'surgeryDate',
            name: i18n.t('evaluation.surgeryDate'),
            data: order.planit && formatDate(String(order.planit.surgery_date)),
          },
          {
            propName: 'observations',
            name: i18n.t('evaluation.observations'),
            data: order.notes,
          },
        ],
      }
  ];

  let measures = (order.exam?.measures || []).map((measure, i) => (
    <InputContainer>
      <strong>{`${i18n.t('evaluation.measure')} ${i + 1} | `}</strong>
      <DefaultInput
        error={'measures' in errors && (!tmpOrderData.exam?.measures?.[i] || Number(tmpOrderData.exam?.measures[i]) === 0)}
        editable={editing}
        disabled={!editing}
        name={`${i}`}
        value={editing && tmpOrderData.exam?.measures ? tmpOrderData.exam?.measures[i] : measure}
        type="text"
        autoComplete="off"
        onChange={(e) => {
          setTmpOrderData((prevState) => ({
            ...prevState,
            exam: {
              ...prevState.exam,
              measures: tmpOrderData.exam?.measures?.map((m, index) => {
                if (i === index) {
                  return e.target.value;
                }
                return m;
              })
            },
          }));
        }}
      />
      {editing &&
        <Metrics>cm</Metrics>
      }
    </InputContainer>
  ));

  const getOrder = async (id?: string) => {
    setLoading(true)

    if (!id) {
      showToast({ type: "error", message: `Empty order id` });
      return;
    }
    api
      .get(`/orders/${id}`)
      .then((response) => {
        setOrder(response.data);
        response.data.exam &&
          setTmpOrderData({
            ...tmpOrderData,
            exam: {
              ...tmpOrderData.exam,
              measures: response.data.exam?.measures
            }
          })
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setNavigationAction({
      action: "function",
      functionToBeExecuted: toggleSeeDetails,
      hasButton: true,
      description: `#${order.incremental_id} ${order.solution?.name} - ${order.patient?.name}`,
    });
  }, [order.incremental_id, order.licensee?.name, order.patient?.name]);

  useEffect(() => {
    if (order_id) {
      getOrder(order_id);
    }
  }, [order_id])

  return (
    <>
      {loading ?
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
        :
        <Container>
          <Header>
            {(order.status !== orderStatus.development && order.status !== orderStatus.completed) &&
              <IconContainer>
                <IconContent >
                  {!editing ?
                    loadingRequest ?
                      <Loader loaderSize={35} />
                      :
                      <>
                        <MdEdit onClick={() => setEditing(true)} />
                        <span>{`${i18n.t(`tips.edit`)}`}</span>
                      </>
                    :
                    <>
                      <IoIosSave
                        color={editing && (order.solution_type === solutionTypes.plan || (order.exam?.measures !== tmpOrderData.exam?.measures || order.exam?.side?.value !== tmpOrderData.exam?.side?.value || Object.keys(tmpOrderData).length > 1)) ? 'var(--fixit)' : 'var(--light-gray)'}
                        onClick={() => (order.solution_type === solutionTypes.plan || (order.exam?.measures !== tmpOrderData.exam?.measures || order.exam?.side?.value !== tmpOrderData.exam?.side?.value || Object.keys(tmpOrderData).length > 1)) && updateOrderDetails()} />
                      <span>{`${i18n.t(`tips.editButton`)}`}</span>
                    </>
                  }
                </IconContent>
                {editing &&
                  <IconContent
                    onClick={() => {
                      setEditing(false)
                      setTmpOrderData({
                        exam: {
                          measures: order.exam?.measures
                        }
                      } as ITmpOrderData)
                      setErrors({})
                    }}>
                    <MdOutlineCancel />
                    <span>{`${i18n.t(`tips.cancel`)}`}</span>
                  </IconContent>
                }
              </IconContainer>
            }
            <TagsContainer>
              <Tag key="status">
                <span>{`${i18n.t(
                  `orders.status.${order.status}.optionText`
                )}`}</span>
              </Tag>

              {order.urgency_type === orderUrgencyTypes.requested && (
                <Tag key="urgency">
                  <span>Urgência solicitada</span>
                </Tag>
              )}

              {order.urgency_type === orderUrgencyTypes.approved && (
                <Tag key="urgency">
                  <span>Urgência aprovada</span>
                </Tag>
              )}

              <Tag key="order_type">
                <span>{`${i18n.t(
                  `financial.prices.order_type.${order.category}`
                )}`}</span>
              </Tag>
              <Tag key="solution">
                <span>{order.solution?.name}</span>
              </Tag>
            </TagsContainer>
          </Header>
          <EvaluationWrapper>
            {(order.exam || order.planit) &&
              mock.map((section: any, index: number) => {
                return (
                  <Section key={index}>
                    <h2>{section.title}</h2>
                    {section.fields.map((field: any) => {
                      if (field.data instanceof Array) {
                        return (
                          <InputContainer>
                            <strong>{`${field.name} | `}</strong>
                            {field.data.map((item: any) => (
                              <>
                                {item.name}
                              </>
                            ))}
                          </InputContainer>
                        );
                      }
                      return !field.data ? null : (
                        <InputContainer>
                          <strong>{field.name} | </strong>
                          <DefaultInput
                            error={`${field.propName}` in errors && (!tmpOrderData[section.fieldName]?.[field.propName])}
                            autoComplete="off"
                            editable={section.editable && editing}
                            disabled={!editing}
                            name={field.name}
                            value={tmpOrderData[section.fieldName]?.[field.propName] ?? field.data}
                            type="text"
                            readOnly={!section.editable}
                            onChange={(event) => {
                              setTmpOrderData((prevState: any) => ({
                                ...prevState,
                                [section.fieldName]: {
                                  ...prevState.patient,
                                  [field.propName]: event.target.value,
                                }
                              }))
                            }}
                          />
                          {(editing && field.metrics) &&
                            <Metrics>{field.metrics}</Metrics>
                          }
                        </InputContainer>
                      );
                    })}
                  </Section>
                );
              })}
            {userHasPrinter &&
              <Section>
                <h2>{`${i18n.t("evaluation.requirements")}`}</h2>
                <InputContainer>
                  <strong>{`${i18n.t("evaluation.printer")} | `} </strong>
                  <DefaultSelect
                    disabled={!editing}
                    name='printers'
                    id='printer-selection'
                    value={editing ? tmpOrderData.printer : order.printer?.id}
                    onChange={(event) => {
                      setTmpOrderData((prevState: any) => {
                        return ({
                          ...prevState,
                          printer: event.target.value
                        });
                      })
                    }}

                  >
                    {
                      user.person.licensee.printers.map(printer => {
                        return (
                          <option key={printer.name} value={printer.id}>
                            {`${printer.name}`}
                          </option>
                        )
                      })
                    }
                  </DefaultSelect>
                </InputContainer>
                {!!order.exam && (
                  <InputContainer>
                    <strong>{`${i18n.t("evaluation.side")} | `} </strong>
                    <DefaultSelect
                      disabled={!editing}
                      name='sides'
                      id='side-selection'
                      value={tmpOrderData.exam?.side?.value ?? order.exam.side}
                      onChange={(e) => {
                        setTmpOrderData((prevState: any) => ({
                          ...prevState,
                          exam: {
                            ...prevState.exam,
                            side: {
                              optionText: i18n.t(`global.sides.${e.target.value}`),
                              value: e.target.value,
                            }
                          }
                        }));
                      }}
                    >
                      {
                        order.solution.sides?.map((side) => {
                          return (
                            <option key={side} value={side}>
                              {`${i18n.t(`global.sides.${side}`)}`}
                            </option>
                          )
                        })
                      }
                    </DefaultSelect>
                  </InputContainer>
                )}
                {measures}
              </Section>
            }
          </EvaluationWrapper>
          {!!order.planit && (
            <Section>
              <h2>{`${i18n.t('evaluation.images')}`}</h2>
              <FlexButtons>
                {order.planit?.files_links?.map((file) => {
                  return (
                    <FileContainer>
                      <File>
                        <span>{`${file.split('/').pop()}`}</span>
                        <RiDeleteBin7Line
                          onClick={async () => {
                            if (
                              window.confirm(
                                "Tem certeza que deseja exluir o arquivo?"
                              )
                            ) {
                              await deletePlanitFile(order.planit_id, file);
                            }
                          }}
                          size={20}
                          color="var(--fixit)"
                        />
                      </File>
                    </FileContainer>
                  );
                })}
              </FlexButtons>
            </Section>
          )}

          { /* order.status !== i18n.t("orders.status.completed.value") && (
          <AbsoluteButton
            color="var(--error)"
            absolute
            onClick={() => {
              if (window.confirm(i18n.t("orders.cancelConfirmation"))) {
                cancelOrder(order.id);
              }
            }}
          >
            <RiDeleteBin6Line />
          </AbsoluteButton>
        )
        */}
        </Container >
      }
    </>
  );
}
