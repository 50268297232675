import { useState } from "react";

import {
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import {
  MdAttachMoney,
  MdOutlineMoneyOff,
  MdOutlinePersonPinCircle,
  MdPublishedWithChanges,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Popover from "../../../components/Popover";
import { i18n } from "../../../localization/i18n";
import {
  ActionItem,
  ActionsContainer,
  IconButtonContainer,
  ProvisionerContainer,
} from "../components/OrderItem/styles";
import { IOrder } from "../dtos";
import { ProvisionerList } from "../components/ProvisionerList";
import { FiGift } from "react-icons/fi";
import { showToast } from "../../../components/CustomToast";
import api from "../../../services/api";
import { ActionsPopup } from "../components/ActionsPopup";
import { solutionTypes } from "../../../utils/constants";

interface ActionsProps {
  order: IOrder;
  onProviderSelect: () => void;
}

export const AdminActions = ({ order, onProviderSelect }: ActionsProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [providerSelected, setProviderSelected] = useState(false);
  const [statusActionsOpen, setStatusActionsOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState(order?.financial_status);
  const [financialStatusModal, setFinancialStatusModal] = useState(false);

  function toggleProvisionerSelected() {
    setProviderSelected(true);
  }
  function handleToggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  const actions = [
    {
      text: i18n.t("orders.financial_status.order_status"),
      icon: <MdPublishedWithChanges />,
      action: () => setStatusActionsOpen((prevState) => !prevState),
    },
  ];

  const orderStatusActions = [
    {
      text: i18n.t(`financial.situation.paid`),
      icon: <MdAttachMoney />,
      active: orderStatus === "paid",
      action: () => {
        handleChangeLicenseeStatus("paid");
      },
    },
    {
      text: i18n.t(`financial.situation.not_paid`),
      icon: <MdOutlineMoneyOff />,
      active: orderStatus === "not_paid",
      action: () => {
        handleChangeLicenseeStatus("not_paid");
      },
    },
    {
      text: i18n.t(`financial.situation.courtesy`),
      icon: <FiGift />,
      active: orderStatus === "courtesy",
      action: () => {
        handleChangeLicenseeStatus("courtesy");
      },
    },
    {
      text: i18n.t(`topbar.coinFix.end`),
      icon: <RiMoneyDollarCircleLine />,
      active: orderStatus === "fixcoin_paid",
      action: () => {
        handleChangeLicenseeStatus("fixcoin_paid");
      },
    },
  ];

  async function handleChangeLicenseeStatus(
    status: IOrder["financial_status"]
  ) {
    try {
      await api.put(
        `/orders/${order.id}/financial_status?financial_status=${status}`
      );

      setOrderStatus(status);

      showToast({
        type: "success",
        message: i18n.t("orders.financial_status.success"),
      });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("orders.financial_status.error"),
      });
    }
  }

  function getFinancialStatusInfos() {
    switch (orderStatus) {
      case "courtesy":
        return {
          label: i18n.t(`financial.situation.courtesy`),
          icon: <FiGift size={18} />,
        };
      case "paid":
        return {
          label: i18n.t(`financial.situation.paid`),
          icon: <MdAttachMoney size={18} />,
        };
      case "not_paid":
        return {
          label: i18n.t(`financial.situation.not_paid`),
          icon: <MdOutlineMoneyOff size={18} />,
        };
      case "fixcoin_paid":
        return {
          label: i18n.t(`topbar.coinFix.end`),
          icon: <RiMoneyDollarCircleLine size={18} />,
        };
    }
  }

  return (
    <>
      <ProvisionerList
        handleProviderSelected={() => {
          onProviderSelect();
          toggleProvisionerSelected();
        }}
        trigger={isModalOpen}
        orderIdentification={order.id}
        onClose={() => {
          handleToggleModal();
        }}
        order={order}
      />
      <ActionsContainer>
        <ActionItem>
          <IconButtonContainer onClick={() => setFinancialStatusModal(true)}>
            {getFinancialStatusInfos().icon}
            {financialStatusModal && (
              <ActionsPopup
                options={orderStatusActions}
                onClickAway={() => setFinancialStatusModal(false)}
              />
            )}
          </IconButtonContainer>
        </ActionItem>

        {!order.printer && order.category === "is_fixiter" && (
          <ActionItem>
            <Popover position="left" label={"FixPoint"}>
              <ProvisionerContainer
                disabled={(order.solution_type === solutionTypes.custom ? order.status !== "completed" : order.file_choices.length === 0)}
                providerSelected={!!order?.provider?.id ?? providerSelected}
              >
                <MdOutlinePersonPinCircle
                  color={`${!order.provider ? ((order.solution_type === solutionTypes.custom && order.status !== "completed") || (order.solution_type === solutionTypes.standard && order.file_choices.length === 0) ? "var(--light-gray)" : (order.solution_type === solutionTypes.custom && order.status === "completed") || (order.solution_type === solutionTypes.standard && order.file_choices.length !== 0 && order.provider) ? "var(--fixit)" : "var(--gray)") : "var(--fixit)"}`}
                  size={18}
                  onClick={() => {
                    ((order.solution_type === solutionTypes.custom ? order.status === "completed" : order.file_choices.length !== 0) || order.provider) && handleToggleModal();
                  }}
                />
              </ProvisionerContainer>
            </Popover>
          </ActionItem>
        )}
      </ActionsContainer>
    </>
  );
};
