import { useEffect, useState } from "react";

import { useAuth } from "../../../hooks/AuthContext";

import { format } from "date-fns";
import { i18n } from "../../../localization/i18n";

import { showToast } from "../../../components/CustomToast";
import { DownloadCard } from "../../creators/components/DownloadCard";
import { EditDiagnosisModal } from "./components/EditDiagnosisModal";
import { CancelOrderModal } from "../components/CancelOrderModal";
import { EditOrderUrgencyModal } from "./components/EditOrderUrgencyModal";

import api from "../../../services/api";

import {
  orderUrgencyTypes,
} from "../../../utils/constants";

import { getActiveLanguage } from "../../../utils/getActiveLanguage";

import { IOrder, MockData } from "./dtos";

import {
  RiDownload2Line,
  RiPencilFill,
} from "react-icons/ri";

import {
  Container,
  EvaluationWrapper,
  Section,
  Field,
  TableDownloadBtn,
  FlexButtons,
  FieldContainer
} from "./styles";
import { LoadingContainer } from "../../creators/pages/MultiStandardSolutionsUpload/styles";
import { Loader } from "../../../components/Loader";


export interface IShowOrder {
  order_id: string;
  toggleSeeDetails: () => void;
}


export default function ShowOrder({ order_id, toggleSeeDetails }: IShowOrder) {
  const { setNavigationAction } = useAuth();
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [editDiagnosisModal, setEditDiagnosisModal] = useState(false);
  const [editOrderUrgencyModal, setEditOrderUrgencyModal] = useState(false);

  const [order, setOrder] = useState<IOrder>({} as IOrder);

  const [loading, setLoading] = useState(false)

  const getOrder = async (order_id?: string) => {
    setLoading(true)
    if (!order_id) {
      showToast({ type: "error", message: `Empty order id` });
      return;
    }
    api
      .get(`/orders/${order_id}`)
      .then((response) => {
        setOrder(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy");
  }

  function formatDiagnosis() {
    const diagnosis: any[] = [];

    order?.exam?.diagnosis_list?.forEach((item) => {
      diagnosis.push({
        name: `${item.name[getActiveLanguage()]} - ${item.cid}`,
        description: item.description[getActiveLanguage()],
      });
    });

    if (order.exam?.diagnosis) {
      diagnosis.push({
        name: order.exam?.diagnosis,
      });
    }

    return diagnosis;
  }

  function getUrgencyInfos() {
    switch (order.urgency_type) {
      case orderUrgencyTypes.requested:
        return "Solicitada";
      case orderUrgencyTypes.approved:
        return "Urgência aprovada";

      default:
        return "Não solicitada";
    }
  }

  useEffect(() => {
    !!order_id && getOrder(order_id);
  }, [order_id]);

  const mock: MockData[] = [
    {
      title: i18n.t("orders.patient"),
      fields: [
        {
          name: i18n.t("evaluation.name"),
          data: order.patient
            ? order.patient.name
            : order.exam
              ? order.exam.patient_name
              : order.planit?.patient_name,
        },
        {
          name: i18n.t("evaluation.age"),
          data: order.patient
            ? order.patient.age
            : order.exam
              ? order.exam.age
              : order.planit?.age,
        },
        {
          name: i18n.t("evaluation.months"),
          data: order.patient
            ? order.patient.months
            : order.exam
              ? order.exam.months
              : order.planit?.months,
        },
        {
          name: i18n.t("evaluation.weight"),
          data: `${order.patient
            ? order.patient.weight
            : order.exam
              ? order.exam.weight
              : order.planit?.weight
            }kg`,
        },
        {
          name: i18n.t("evaluation.height"),
          data: `${order.patient
            ? order.patient.height
            : order.exam
              ? order.exam.height
              : order.planit?.height
            }cm`,
        },
        {
          name: i18n.t("profile.phone"),
          data: `${order.patient?.phone ?? i18n.t("orders.patientNotSet")}`,
        },
        {
          name: i18n.t("profile.email"),
          data: `${order.patient?.email ?? i18n.t("orders.patientNotSet")}`,
        },
      ],
    },
    {
      title: i18n.t("evaluation.evaluation"),
      fields: [
        {
          name: i18n.t("evaluation.diagnosis"),
          data: formatDiagnosis(),
          icon: (
            <RiPencilFill
              color="var(--fixit)"
              onClick={() => setEditDiagnosisModal(true)}
            />
          ),
        },
        {
          name: i18n.t("evaluation.previousOrthesis"),
          data:
            order.exam &&
            order.exam.prior_use != null &&
            (order.exam.prior_use ? "Sim" : "Não"),
        },
        {
          name: i18n.t("evaluation.indication"),
          data: (
            order.exam
              ? order.exam.any_indication
              : order.planit?.any_indication
          )
            ? "Sim"
            : "Não",
        },
        {
          name: i18n.t("evaluation.professional"),
          data: order.planit
            ? `${order.planit?.doctor_name} - ${order.planit?.doctor_contact}`
            : undefined,
        },
        { name: i18n.t("evaluation.bodyPart"), data: order.planit?.body_part },
        { name: i18n.t("evaluation.segmentaion"), data: order.planit?.target },
        {
          name: i18n.t("evaluation.injuryType"),
          data: order.planit?.injury_type,
        },
        {
          name: i18n.t("evaluation.surgeryDate"),
          data: order.planit && formatDate(order.planit.surgery_date),
        },
        {
          name: i18n.t("evaluation.observations"),
          data: order.notes,
        },
        {
          name: i18n.t("evaluation.urgency"),
          data: getUrgencyInfos(),
          icon: (
            <>
              {order.solution_type === "custom" && (
                <RiPencilFill
                  color="var(--fixit)"
                  onClick={() => setEditOrderUrgencyModal(true)}
                />
              )}
            </>
          ),
        },
        {
          name: `${i18n.t("evaluation.urgencyCause")}`,
          data:
            order.urgency_type === orderUrgencyTypes.requested &&
              !order?.urgency_reason
              ? `${i18n.t("evaluation.urgencyNotComprooved")}`
              : order.urgency_reason ?? "",
        },
      ],
    },
  ];

  let measures = (order.exam?.measures || []).map((measure, i) => (
    <Field>
      <strong>{`${i18n.t("evaluation.measure")} ${i + 1}: `}</strong>
      {`${measure} cm`}
    </Field>
  ));

  function DeleteFile(fileId: string) {
    api
      .delete(`/files/${fileId}`)
      .then(() => {
        getOrder();
      })
      .catch((err) => {
        showToast({
          type: "error",
          message: "Ocorreu um erro ao tentar excluir o arquivo",
        });
      });
  }

  const toggleOrderCancel = () => {
    setIsCancelOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setNavigationAction({
      action: "function",
      functionToBeExecuted: toggleSeeDetails,
      hasButton: true,
      description: `#${order.incremental_id} ${order.solution?.name} - ${order.patient?.name}`,
    });
  }, [order.incremental_id, order.licensee?.name, order.patient?.name]);

  return (
    <>
      <CancelOrderModal
        trigger={isCancelOpen}
        orderIdentification={order.id}
        onClose={toggleOrderCancel}
      />

      <EditDiagnosisModal
        open={editDiagnosisModal}
        handleCloseModal={() => setEditDiagnosisModal(false)}
        onDiagnosisEdited={getOrder}
        exam={order?.exam}
      />

      <EditOrderUrgencyModal
        open={editOrderUrgencyModal}
        order={order}
        handleCloseModal={() => setEditOrderUrgencyModal(false)}
        onOrderUpdated={getOrder}
      />

      {loading ?
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
        :
        <Container>
          <EvaluationWrapper>
            {(order.exam || order.planit) &&
              mock.map((section, index) => {
                return (
                  <Section key={index}>
                    <h2>{section.title}</h2>
                    {section.fields.map((field) => {
                      if (field.data instanceof Array) {
                        return (
                          <FieldContainer>
                            <Field>
                              <strong>{field.name}:</strong>
                              {field.data.map((item) => (
                                <>
                                  {item.name}
                                  {field?.icon && field?.icon}
                                </>
                              ))}
                            </Field>
                          </FieldContainer>
                        );
                      }
                      return field.data === undefined ? null : (
                        <Field>
                          <strong>{field.name}:</strong> {field.data}{" "}
                          {field?.icon && field?.icon}
                        </Field>
                      );
                    })}
                  </Section>
                );
              })}

            <Section>
              <h2>{`${i18n.t("evaluation.requirements")}`}</h2>
              <Field>
                <strong>{`${i18n.t("evaluation.printer")}`}: </strong>
                {order.printer?.name}
              </Field>
              <Field>
                <strong>{`${i18n.t("evaluation.side")}`}: </strong>
                {order.exam?.side}
              </Field>
              {measures}
            </Section>
          </EvaluationWrapper>

          <EvaluationWrapper>
            {!!order.planit && (
              <Section>
                <h2>{`${i18n.t("evaluation.images")}`}</h2>
                <FlexButtons>
                  {order.planit?.files_links?.map((file) => {
                    return (
                      <a
                        href={`${process.env.REACT_APP_STORAGE_BASE_URL}${file}`}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        <TableDownloadBtn>
                          <RiDownload2Line size={20} />
                        </TableDownloadBtn>
                      </a>
                    );
                  })}
                </FlexButtons>
              </Section>
            )}

            <Section>
              <h2>{`${i18n.t("evaluation.files")}`}</h2>
              <FlexButtons>
                {order.files?.map((file) => {
                  return (
                    <DownloadCard
                      file_id={file.id}
                      originalName={file.original_name}
                      fileLink={`${file.link}`}
                      fileSize={333}
                      onDelete={() => DeleteFile(file.id)}
                    />
                  );
                })}
              </FlexButtons>
            </Section>
          </EvaluationWrapper>
        </Container>
      }
      {/*{(user.role === roles.saf ||
        user.role === roles.creator ||
        user.role === roles.admin) &&
        order.status !== i18n.t("orders.status.cancelled.value") && (
          <AbsoluteButton
            color="var(--error)"
            absolute
            onClick={() => {
              if (window.confirm(i18n.t("orders.cancelConfirmation"))) {
                if (user.role === roles.saf) {
                  toggleOrderCancel();
                  return;
                }
                cancelOrder(order.id);
              }
            }}
          >
            <RiDeleteBin6Line />
          </AbsoluteButton>
        )}
      */}
    </>
  );
}
