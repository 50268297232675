import styled from "styled-components";
import global from "../../../global";

export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    flex-wrap: wrap;
  }
`;

export const Section = styled.section`
  h2 {
    color: var(--dark-gray);
    padding-bottom: 12px;
  }

  margin-right: 20px;
  margin-bottom: 20px;

  padding: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  min-width: 300px;
  width: calc(50% - 30px);

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    margin-right: 0;
  }
`;

export const FieldContainer = styled.div``

export const Field = styled.div`
  color: var(--dark-gray);
  padding-top: 6px;
  width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 6px;

  svg {
    margin-left: 2px;
    cursor: pointer;
  }
`;

export const TableDownloadBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  margin-right: 6px;

  border-radius: 6px;
  background-color: var(--light-gray);
  color: var(--dark-gray);
  cursor: pointer;
`;
export const FlexButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
