import { ReactNode, useEffect, useState } from "react";
import Popover from "../../../../components/Popover";

import {
  Container,
  LeftContent,
  ImageContainer,
  ContentInfos,
  PrincipalInfos,
  PriceInfos,
  RightContent,
  Header,
  MobileContent,
  QuantityInfos,
  IconButtonContainer,
  ProvisionerContainer,
  ActionItem,
  FixiterPartnerContainer,
} from "./styles";
import { IOrder, IPopupData } from "../../dtos";

import AwardImg from "../../../../assets/icons/award_standards_plano.svg";
import PenImg from "../../../../assets/icons/pen_custom_plano.svg";
import { format } from "date-fns";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import {
  orderSituation,
  orderStatus,
  orderUrgencyTypes,
  roles,
  solutionTypes,
} from "../../../../utils/constants";
import { LicenseeActions } from "../../actions/LicenseeActions";
import { CreatorActions } from "../../actions/CreatorActions";
import api from "../../../../services/api";
import { SAFActions } from "../../actions/SAFActions";
import { FixiterActions } from "../../actions/FixiterActions";
import { AdminActions } from "../../actions/AdminActions";
import { abridgedControl } from "../../../../utils/strings";
import { BiTestTube } from "react-icons/bi";
import { FiGift } from "react-icons/fi";
import { MdAttachMoney, MdOutlineCancel, MdOutlineMoneyOff, MdOutlinePersonPinCircle } from "react-icons/md";
import { RiEyeLine, RiMoneyDollarCircleLine, RiPencilLine } from "react-icons/ri";
import { usePrinters } from "../../../../hooks/PrintersContext";
import { OrderProviderModal } from "../OrderProviderModal";
import { showToast } from "../../../../components/CustomToast";

interface PriceItemProps {
  onClick?: () => void;
  onSeeIconClick?: () => void;
  onUpdate?: (order: IOrder) => void;
  getOrders: (reload: boolean) => Promise<void>;
  setIsProviderSelected: (value: React.SetStateAction<boolean>) => void;
  setOrderPrintManagement: (
    value: React.SetStateAction<IOrder | undefined>
  ) => void;
  order: IOrder;
  children: ReactNode;
  openPopup: ({
    order,
    printTime,
    cost,
    weight,
    link,
    printer,
    type,
  }: IPopupData) => void;
  printOrder: (order: any) => void;
  printFile: ({
    order,
    printTime,
    cost,
    weight,
    printer,
    type,
  }: IPopupData) => void;
  openPrintHistory: ({
    orderId,
    quantity,
  }: {
    orderId: string;
    quantity: number;
  }) => void;
  orderEdition: ({
    orderId,
  }: {
    orderId: string,
  }) => void;
  openPlanitFileUploadPopup: (planit_id: string) => void;
  setSelectedOrderId: (order_id: string) => void
  seeDetails: () => void
}

interface IUser {
  name: string;
}

export function OrderItem({
  order,
  onClick = () => { },
  onSeeIconClick = () => { },
  onUpdate = () => { },
  children,
  getOrders,
  setIsProviderSelected,
  setOrderPrintManagement,
  openPopup,
  printOrder,
  printFile,
  openPrintHistory,
  orderEdition,
  openPlanitFileUploadPopup,
  seeDetails,
  setSelectedOrderId
}: PriceItemProps) {
  const { user, currency } = useAuth();
  const { userHasPrinter } = usePrinters()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileListDropdownOpen, setIsFileListDropdownOpen] = useState(false);
  const [isPrintOptionDropdownOpen, setIsPrintOptionDropdownOpen] =
    useState(false);
  const [creator, setCreator] = useState<IUser>();

  async function cancelOrder() {
    try {
      if (window.confirm(i18n.t("orders.cancelConfirmation"))) {
        api.patch(`/orders/${order.id}`, {
          status: orderStatus.cancelled,
        });
        showToast({
          type: "success",
          message: i18n.t("optionsBtn.successMessage"),
        });
        onUpdate({ ...order, status: "cancelled" })
      }
    } catch (error) {
      showToast({ type: "error", message: `${error}` });
    }
  }

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' 'HH:mm");
  }

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  useEffect(() => {
    (user.role === roles.creator || user.role === roles.saf) && (
      order?.creator_id &&
      api.get(`/users/${order.creator_id}`).then((response) => {
        setCreator(response.data);
      }))
  }, [order, user.role]);

  const orderFinancialStatusIcon = {
    paid: {
      label: i18n.t(`financial.situation.paid`),
      icon: <MdAttachMoney size={18} />,
    },
    not_paid: {
      label: i18n.t(`financial.situation.not_paid`),
      icon: <MdOutlineMoneyOff size={18} />,
    },
    courtesy: {
      label: i18n.t(`financial.situation.courtesy`),
      icon: <FiGift size={18} />,
    },
    fixcoin_paid: {
      label: i18n.t(`topbar.coinFix.end`),
      icon: <RiMoneyDollarCircleLine size={18} />,
    },
  };

  return (
    <>
      <OrderProviderModal
        trigger={isModalOpen}
        orderIdentification={order.id}
        onClose={() => {
          toggleModal();
        }}
        order={order}
      />
      <Container>
        <Header>
          <LeftContent>
            <ImageContainer className={order.status}>
              <Popover
                position="right"
                label={`${i18n.t(
                  `financial.prices.solution_type.${order.solution_type}`
                )} - ${i18n.t(`orders.status.${order.status}.optionText`)}`}
              >
                <img
                  alt="standard-solution"
                  src={order.solution_type === "standard" ? AwardImg : PenImg}
                />
              </Popover>
            </ImageContainer>
            <PrincipalInfos className={order.status}>
              <span className="solution">
                #{order.incremental_id} {order.solution?.name}{" "}
                {!!order.solution_standard
                  ? ` (${order.solution_standard.size}-${order.exam?.side || ""})`
                  : ""}
              </span>
              <span className="date">{formatDate(order.created_at)}</span>
            </PrincipalInfos>

            {order.licensee && (
              <ContentInfos className="licensee desktop">
                <span className="data_label">
                  {order.licensee.is_test
                    ? `Licenciado teste`
                    : `${i18n.t("orders.licensee")}`}{" "}
                  {order.licensee.is_test && <BiTestTube />}
                </span>
                <span className="data_value">
                  {abridgedControl(order.licensee.name, 15)}
                </span>
              </ContentInfos>
            )}

            {order.solution_type !== solutionTypes.standard &&
              <ContentInfos className="patient desktop">
                <span className="data_label">{`${i18n.t("orders.patient")}`}</span>
                <span className="data_value">
                  {order.patient
                    ? abridgedControl(order.patient.name, 13)
                    : order.exam
                      ? abridgedControl(order.exam.patient_name, 13)
                      : order.planit
                        ? abridgedControl(order.planit.patient_name, 13)
                        : `${i18n.t("orders.patientNotSet")}`}
                </span>
              </ContentInfos>
            }

            {(user.role === roles.creator && order.solution_type !== solutionTypes.standard) &&
              <ContentInfos className="patient desktop">
                <span className="data_label">{`${i18n.t("orders.printer")}`}</span>
                <span className="data_value">
                  {order.printer.name}
                </span>
              </ContentInfos>
            }

            {
              (user.role === roles.licensee || user.role === roles.employee) && !userHasPrinter && user.id !== order.requester?.user_id ?
                <ContentInfos className="patient desktop">
                  <span className="data_label">{`${i18n.t("orders.order_provider.requester")}`}</span>
                  <span className="data_value">
                    {order.requester
                      ? abridgedControl(order.requester.name, 13)
                      : `${i18n.t("orders.patientNotSet")}`}
                  </span>
                </ContentInfos>
                :
                user.role === roles.licensee && userHasPrinter && user.id !== order.user_id &&
                <ContentInfos className="patient desktop">
                  <span className="data_label">{`${i18n.t("orders.order_provider.requester")}`}</span>
                  <span className="data_value">
                    {abridgedControl(order.user.email, 13)}
                  </span>
                </ContentInfos>
            }

            {
              (user.role === roles.licensee || user.role === roles.employee) && userHasPrinter && order?.provider?.id === user.person.licensee.id &&
              <>
                <ContentInfos className="licensee desktop">
                  <span className="data_label">{`${i18n.t("orders.order_provider.requester")}`}</span>
                  <span className="data_value">
                    {order.requester
                      ? abridgedControl(order.requester.name, 13)
                      : `${i18n.t("orders.patientNotSet")}`}
                  </span>
                </ContentInfos>
                <ContentInfos className="licensee desktop">
                  <span className="data_label">{`${i18n.t("customSolution.contact")}`}</span>
                  <span className="data_value">
                    {order.requester.phone}
                  </span>
                </ContentInfos>
                <FixiterPartnerContainer
                  onClick={() => {
                    toggleModal();
                  }}>
                  <ProvisionerContainer
                    providerSelected={!!order?.provider?.id ?? false}
                  >
                    <IconButtonContainer>
                      <MdOutlinePersonPinCircle
                        size={16}
                      />
                    </IconButtonContainer>
                  </ProvisionerContainer>
                  <span>Fixiter parceiro</span>
                </FixiterPartnerContainer>
              </>
            }

            {creator &&
              (user.role === roles.creator || user.role === roles.saf) && (
                <ContentInfos className="creator desktop">
                  <span className="data_label">{`${i18n.t(
                    "orders.creator"
                  )}`}</span>
                  <span className="data_value">
                    {abridgedControl(creator.name, 15)}
                  </span>
                </ContentInfos>
              )}
          </LeftContent>

          <RightContent>
            {user.role !== roles.creator && (
              <>
                {(!userHasPrinter && user.role === roles.licensee) || user.role === roles.employee ? null : (
                  <PriceInfos
                    urgencyNonJustified={
                      order.urgency_type === orderUrgencyTypes.requested ||
                      order.urgency_type === orderUrgencyTypes.approved
                    }
                  >
                    <span className="price_name">
                      {`${i18n.t(`orders.total`, {
                        order_type: i18n.t(
                          `financial.prices.order_type.${order.category}`
                        ),
                      })}`}
                    </span>
                    {order.financial_status === orderSituation.courtesy &&
                      (user.role === roles.fixiter ||
                        user.role === roles.licensee) ? (
                      <span className="price_location">
                        {`${i18n.t(`financial.situation.courtesy`)}`}
                      </span>
                    ) : order.financial_status === orderSituation.paid &&
                      (user.role === roles.fixiter ||
                        user.role === roles.licensee) ? (
                      <span className="price_location">
                        {`${i18n.t(`financial.situation.paid`)}`}
                      </span>
                    ) : (
                      <>
                        {order.urgency_type === orderUrgencyTypes.requested ||
                          order.urgency_type === orderUrgencyTypes.approved ? (
                          <Popover position="left" label="Pedido de urgência">
                            <span className="price_location">
                              {parseFloat(order.total.toString()).toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: currency?.code ?? "BRL",
                                }
                              )}
                            </span>
                          </Popover>
                        ) : (
                          <span className="price_location">
                            {parseFloat(order.total.toString()).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: currency?.code ?? "BRL",
                              }
                            )}
                          </span>
                        )}
                      </>
                    )}
                  </PriceInfos>
                )}
                <QuantityInfos
                  completed={order.quantity === order.file_choices.filter(file => file.completed).length}
                >
                  <span className="quanitity_name">
                    {`${i18n.t(`orders.qtd`)}`}
                  </span>
                  <span className="quanitity_value">
                    {order.file_choices.filter(file => file.completed).length}/{order.quantity}
                  </span>
                </QuantityInfos>
              </>
            )}
            {(user.role === roles.licensee || user.role === roles.employee || user.role === roles.fixiter) ? userHasPrinter ? (
              <LicenseeActions
                order={order}
                isFileListDropdownOpen={isFileListDropdownOpen}
                isPrintOptionDropdownOpen={isPrintOptionDropdownOpen}
                setIsFileListDropdownOpen={setIsFileListDropdownOpen}
                setIsPrintOptionDropdownOpen={setIsPrintOptionDropdownOpen}
                openPopup={openPopup}
                printOrder={printOrder}
                printFile={printFile}
                openPrintHistory={openPrintHistory}
                openPlanitFileUploadPopup={openPlanitFileUploadPopup}
              />
            ) :
              <FixiterActions
                printOrder={printOrder}
                order={order}
                isPrintOptionDropdownOpen={isPrintOptionDropdownOpen}
                setIsPrintOptionDropdownOpen={setIsPrintOptionDropdownOpen}
              />
              : null}

            {user.role === roles.admin && (
              <AdminActions
                onProviderSelect={() => getOrders(true)}
                order={order}
              />
            )}

            {user.role === roles.creator && (
              <CreatorActions
                order={order}
                getOrders={() => getOrders(true)}
                setOrderPrintManagement={setOrderPrintManagement}
                onUpdate={onUpdate}
              />
            )}

            {user.role === roles.saf && (
              <SAFActions
                order={order}
                getOrders={getOrders}
                setOrderPrintManagement={setOrderPrintManagement}
              />
            )}

            {order.solution_type !== solutionTypes.standard &&
              <ActionItem>
                <Popover position="left" label={`${i18n.t("orders.view")}`}>
                  <IconButtonContainer
                    disabled={
                      order.status === i18n.t("orders.status.cancelled.value")
                    }
                  >
                    <RiEyeLine
                      size={18}
                      onClick={() => {
                        if (order.status !== i18n.t("orders.status.cancelled.value")) {
                          seeDetails()
                          setSelectedOrderId(order.id)
                        }
                      }}
                    />
                  </IconButtonContainer>
                </Popover>
              </ActionItem>
            }

            {user.role !== roles.creator && (
              <>
                {/*{order.solution_type !== solutionTypes.standard && (
                  <ActionItem>
                    <Popover position="left" label={`${i18n.t("tips.edit")}`}>
                      <IconButtonContainer
                        disabled={
                          order.status === i18n.t("orders.status.cancelled.value") ||
                          order.quantity === order.file_choices.filter(file => file.completed).length ||
                          (
                            order.status !== i18n.t("orders.status.processing.value") &&
                            order.status !== i18n.t("orders.status.review.value")
                          )

                        }
                      >
                        <RiPencilLine
                          size={18}
                          onClick={() => {
                            orderEdition({
                              orderId: order.id,
                            })
                          }}
                        />
                      </IconButtonContainer>
                    </Popover>
                  </ActionItem>
                )}
                */}

                < ActionItem >
                  <Popover position="left" label={`${i18n.t("fixiterOnboarding.cancel")}`}>
                    <IconButtonContainer
                      disabled={
                        // Se já estiver sido cancelado
                        order.status === i18n.t("orders.status.cancelled.value") ||
                        // Se o pedido já tiver sido impresso
                        order.quantity === order.file_choices.filter(file => file.completed).length ||
                        // Se o pedido já estiver em desenvolvimento ou concluido
                        (order.solution_type !== solutionTypes.standard && (
                          order.status !== i18n.t("orders.status.processing.value") &&
                          order.status !== i18n.t("orders.status.review.value")
                        ))
                      }
                      onClick={() => {
                        cancelOrder()
                      }}
                    >
                      <MdOutlineCancel
                        size={18}
                      />
                    </IconButtonContainer>
                  </Popover>
                </ActionItem>
              </>
            )}

          </RightContent>
        </Header>
        <MobileContent>
          <LeftContent>
            <ContentInfos>
              <span className="data_label">{`${i18n.t("orders.patient")}`}</span>
              <span className="data_value">
                {order.patient
                  ? order.patient.name
                  : order.exam
                    ? order.exam.patient_name
                    : order.planit
                      ? order.planit.patient_name
                      : `${i18n.t("orders.patientNotSet")}`}
              </span>
            </ContentInfos>

            {(user.role === roles.creator) &&
              <ContentInfos>
                <span className="data_label">{`${i18n.t("orders.printer")}`}</span>
                <span className="data_value">
                  {order.printer.name}
                </span>
              </ContentInfos>
            }

            {
              user.role === roles.licensee && userHasPrinter && user.id !== order.user_id &&
              <ContentInfos className="patient desktop">
                <span className="data_label">{`${i18n.t("orders.order_provider.requester")}`}</span>
                <span className="data_value">
                  {abridgedControl(order.user.email, 13)}
                </span>
              </ContentInfos>
            }

            {order.partner && (
              <ContentInfos className="partner">
                <span className="data_label">{`${i18n.t(
                  "orders.partner"
                )}`}</span>
                <span className="data_value">{order.partner.name}</span>
              </ContentInfos>
            )}
          </LeftContent>
        </MobileContent>
      </Container >
    </>
  );
}
